import React from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { getUserdata } from "../helpers/function.jsx";
import { PageSettings } from "./../../config/page-settings.js";

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileActive: 0,
    };
    this.handleProfileExpand = this.handleProfileExpand.bind(this);
  }

  handleProfileExpand(e) {
    e.preventDefault();
    this.setState((state) => ({
      profileActive: !this.state.profileActive,
    }));
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li
              className={
                "nav-profile " + (this.state.profileActive ? "expand " : "")
              }
            >
              <Link to="/" onClick={this.handleProfileExpand}>
                <div
                  className="cover with-shadow"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${
                      getUserdata().kode_toko === "GIA"
                        ? require("../../assets/images/GIA.jpg")
                        : require("../../assets/images/cover-sidebar-user.jpg")
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="image">
                  <Avatar
                    name={getUserdata().user_id}
                    color="#00743C"
                    size="30"
                    round="50px"
                  />
                </div>
                <div className="info">
                  {/* <b className="caret pull-right"></b> */}
                  <small>{getUserdata().user_name}</small>
                </div>
              </Link>
            </li>
            {/* <li>
							<ul className={"nav nav-profile " + (this.state.profileActive && !pageSidebarMinify ? "d-block " : "")}>
								<li><Link to="/"><i className="fa fa-cog"></i> Settings</Link></li>
								<li><Link to="/"><i className="fa fa-pencil-alt"></i> Send Feedback</Link></li>
								<li><Link to="/"><i className="fa fa-question-circle"></i> Helps</Link></li>
							</ul>
						</li> */}
          </ul>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default SidebarProfile;
