import React, { useEffect } from "react";
import { PanelContent } from "../../components/panel/panel";
import { change, Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { postData } from "../../config/axios";
import { convertDate } from "../../components/helpers/function";
import { ToastNotification } from "../../components/helpers/notification";
import { isLoading } from "../../actions/datamaster_action";
import { InputDate, ReanderSelect } from "../../components/helpers/field";
import { useState } from "react";

let DelteDataTrancate = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change("FormDelteDataTrancate", "tgl_awal", new Date()));
    dispatch(change("FormDelteDataTrancate", "tgl_akhir", new Date()));
    dispatch(change("FormDelteDataTrancate", "type", "PENJUALAN"));
  }, [dispatch]);

  const prosesDeleteDate = async (data) => {
    try {
      dispatch(isLoading(true));
      let url =
        data.type === "PENJUALAN"
          ? "truncate/delete-penjualan"
          : data.type === "KEUANGAN"
            ? "truncate/delete-keuangan"
            : "truncate/delete-pembelian";
      await postData(url, {
        tgl_from: convertDate(data.tgl_awal),
        tgl_to: convertDate(data.tgl_akhir)
      });
      dispatch(isLoading(false));
      ToastNotification("success", "Data Berhasil Di Hapus");
    } catch (error) {
      dispatch(isLoading(false));
      ToastNotification(
        "info",
        error?.response?.data || "Terjadi kesalahan saat mengirim data"
      );
    }
  };

  const [tgl, settgl] = useState({
    tgl_from: new Date(),
    tgl_to: new Date()
  });
  return (
    <PanelContent title="Delete Data Penjualan">
      <form onSubmit={handleSubmit(prosesDeleteDate)} autoComplete="off">
        {/* <FilterLaporanTgl nameButton={"Delete Data"} /> */}
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="type"
              component={ReanderSelect}
              label={"Type"}
              type="text"
              options={[
                {
                  value: "PENJUALAN",
                  name: "PENJUALAN"
                },
                {
                  value: "PEMBELIAN",
                  name: "PEMBELIAN"
                },
                {
                  value: "KEUANGAN",
                  name: "KEUANGAN"
                }
              ]}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label={"Tanggal Dari"}
              type="text"
              selected={tgl.tgl_from}
              onChange={(date) =>
                settgl({
                  ...tgl,
                  tgl_from: new Date(date)
                })
              }
              placeholder="Masukkan Tanggal Dari"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              label={"Tanggal Dari"}
              type="text"
              selected={tgl.tgl_to}
              onChange={(date) =>
                settgl({
                  ...tgl,
                  tgl_to: new Date(date)
                })
              }
              placeholder="Masukkan Tanggal Dari"
            />
          </div>
          <div className="col-lg-3 mt-4">
            <button className="btn btn-primary"> Hapus Data </button>
          </div>
        </div>
      </form>
    </PanelContent>
  );
};

// export default DelteDataTrancate
DelteDataTrancate = reduxForm({
  form: "FormDelteDataTrancate",
  enableReinitialize: true
})(DelteDataTrancate);
export default DelteDataTrancate;
