import {
  DATA_NOTA,
  GET_DATA_TIMBANGAN,
  GET_PARA_SYSTEM,
  GET_PARA_SYSTEM_ID
} from "../actions/utility_action";

const initialState = {
  getTimbangan: [],
  getParaSystem: [],
  getParaSystemId: [],
  getDataNota: []
};

const utility = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_DATA_TIMBANGAN:
      return {
        ...state,
        getTimbangan: actions.payload.data
      };
    case GET_PARA_SYSTEM:
      return {
        ...state,
        getParaSystem: actions.payload.data
      };
    case DATA_NOTA:
      return {
        ...state,
        getDataNota: actions.payload.data
      };
    case GET_PARA_SYSTEM_ID:
      return {
        ...state,
        getParaSystemId: actions.payload.data
      };
    default:
      return state;
  }
};

export default utility;
