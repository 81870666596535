import { useSelector } from "react-redux";
import {
    Field,
    InputDate,
    React,
    selectorMaster,
    useState,
    // reduxForm,
    // useDispatch,
    // useEffect,
    // change,
} from "./library";

let FilterLaporanTgl = ({ handleSubmit, nameButton, disabledTglAkhir, cusTomeNameTglAwal, disabledButton }) => {
    const [tgl_awal, setTglAwal] = useState(new Date());
    const [tgl_akhir, setTglAkhir] = useState(new Date());
    const loading = useSelector(selectorMaster.loading);

    return (
        // <form onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
            <div className="col-lg-3">
                <Field
                    name="tgl_awal"
                    component={InputDate}
                    label={cusTomeNameTglAwal || "Tanggal Dari"}
                    type="text"
                    selected={tgl_awal}
                    onChange={(date) => setTglAwal(new Date(date))}
                    placeholder="Masukkan Tanggal Dari"
                />
            </div>
            {disabledTglAkhir === true ? '' : (
                <div className="col-lg-3">
                    <Field
                        name="tgl_akhir"
                        component={InputDate}
                        type="text"
                        selected={tgl_akhir}
                        onChange={(date) => setTglAkhir(new Date(date))}
                        label="Tanggal Akhir"
                        placeholder="Masukkan Tanggal Akhir"
                    />
                </div>
            )}
            {disabledButton === true ? '' :
                <div className="col-lg-3">
                    <div className="text-right">
                        <label>&nbsp;</label>
                        <button
                            type="submit"
                            value="PDF"
                            name="type_btn"
                            className="btn btn-primary btn-block"
                        >
                            {loading ? (
                                <>
                                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                                    Mencari
                                </>
                            ) : (
                                nameButton || "Cari Laporan"
                            )}
                        </button>
                    </div>
                </div>
            }
        </div>
        // </form>
    );
};

// FilterLaporanTgl = reduxForm({
//   form: "FilterLaporanTgl",
//   enableReinitialize: true
// })(FilterLaporanTgl);
export default FilterLaporanTgl;
