export const Gramasi = (value, previousValue) => {
  if (value) {
    return value.replace(/[^0-9.]+/g, "");
  } else {
    return value;
  }
};
export const Kadar = (value, previousValue) => {
  if (value) {
    return value.replace(/[^0-9.K]+/g, "");
  } else {
    return value;
  }
};

export const ReplaceValue = value => {
  if (value) {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

export const NumberOnly = (value, previousValue) => {
  if (value) {
    return value.replace(/[^\d]/g, "");
  } else {
    return value;
  }
};
