import React from "react";
import access from "../../assets/accessDenied.svg";
import {
    Link,
    getUserdata,
    capitalize,
    setItem,
    getData
} from "../../components/helpers/library";
import { useEffect } from "react";

const AksesTidakDiizinkan = () => {
    useEffect(() => {
        getPembulatan();
    }, []);

    const getPembulatan = async () => {
        try {
            let res = await getData("para-system/key/PEMBULATAN");
            setItem("pembulatan", { pembulatan: res.data?.value || 500 });
        } catch (error) {
            setItem("pembulatan", { pembulatan: 500 });
        }
    };

    return (
        <div className="container text-center mt-5 ">
            <div className="align-item-center">
                {window.location.pathname === "/dashboard" ? (
                    <div className="card" style={{ height: "130px" }}>
                        <div className="card-body mt-4 ">
                            <h1>
                                {" "}
                                Selamat Datang Kembali{" "}
                                {capitalize(getUserdata().user_id.toLowerCase())} <br />
                            </h1>
                        </div>
                    </div>
                ) : (
                    <>
                        <img src={access} alt="Access" width="30%" />
                        <h1> Mohon Maaf</h1>
                        <h1 className="f-w-900">Akses Di Menu Ini Tidak Di Izinkan</h1>
                        <h5>Hubungi Admin Jika Ingin Memakai Menu Ini</h5>
                        <div>
                            <Link to={"/dashboard"}>
                                <button className="btn btn-primary mt-3">
                                    <i className="fa fa-chevron-left mr-3"></i> Go Home
                                </button>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AksesTidakDiizinkan;
// export default withRouter(AksesTidakDiizinkan);
