import jsPDF from "jspdf";
import "jspdf-autotable";
import { getToday, getUserdata, getItem, } from "../../../../../components/helpers/library";
const LaporanPenjualanTaxPurposePdf = async (data = "", type_laporan) => {
    const doc = new jsPDF("l", "mm", [297, 210]);
    //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
    let tableRows = [];
    // let footRows = [];
    let tableColumn = [];
    let finalY = 35;
    doc.setFontSize(15);
    //   doc.text("Laporan Penjualan Rekap", 210, 15);
    let judulLaporan = doc.splitTextToSize(
        "Laporan Penjualan Rekap ",
        100
    );
    doc.text(judulLaporan, 220, 15);
    doc.text(getUserdata().nama_toko, 14, 15);
    doc.setFontSize(8);
    let jml_alamat = getUserdata().alamat_toko.length;
    if (jml_alamat > 20) {
        doc.text(getUserdata().alamat_toko.slice(0, 48), 14, 20);
    }
    if (jml_alamat > 50) {
        doc.text(getUserdata().alamat_toko.slice(48, 90), 14, 25);
    }
    doc.setFontSize(10);
    doc.setProperties({
        title: "Laporan Penjualan Rekap"
    });
    doc.text(
        `Tanggal : ${type_laporan.tgl_from} s/d ${type_laporan.tgl_to}`,
        220,
        23
    );


    tableColumn = [
        [
            {
                content: `TANGGAL`
            },
            {
                content: `PENJUALAN`,
                styles: {
                    halign: "right"
                }
            },
            {
                content: `PENGGANTI`,
                styles: {
                    halign: "right"
                }
            },
            {
                content: `ONGKOS`,
                styles: {
                    halign: "right"
                }
            },
            {
                content: `DPP`,
                styles: {
                    halign: "right"
                }
            },
            {
                content: `PPN`,
                styles: {
                    halign: "right"
                }
            },
        ]
    ];

    data.forEach((row, index) => {
        let rows = [
            {
                content: row._id || "-"
            },
            {
                content: row.harga_total.toLocaleString('kr-ko') || "-",
                styles: {
                    halign: "right"
                }
            },
            {
                content: row.total_24k.toLocaleString('kr-ko') || "-",
                styles: {
                    halign: "right"
                }
            },
            {
                content: (parseInt(row.harga_total) - parseInt(row.total_24k)).toLocaleString('kr-ko') || "0",
                styles: {
                    halign: "right"
                }
            },
            {
                content: parseInt(row.dpp).toLocaleString('kr-ko') || "-",
                styles: {
                    halign: "right"
                }
            },
            {
                content: parseInt(row.ppn).toLocaleString('kr-ko') || "-",
                styles: {
                    halign: "right"
                }
            },


        ];

        tableRows.push(rows);
    });

    let footer = [

        {
            content: ``,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },
        {
            content: `${data.reduce((a, b) => a + parseInt(b.harga_total), 0).toLocaleString('kr-ko')}`,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },
        {
            content: `${data.reduce((a, b) => a + parseInt(b.total_24k), 0).toLocaleString('kr-ko')}`,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },
        {
            content: `${data.reduce((a, b) => a + (parseInt(b.harga_total) - parseInt(b.total_24k)), 0).toLocaleString('kr-ko')}`,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },

        {
            content: `${data.reduce((a, b) => a + parseInt(b.dpp), 0).toLocaleString('kr-ko')}`,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },
        {
            content: `${data.reduce((a, b) => a + parseInt(b.ppn), 0).toLocaleString('kr-ko')}`,
            styles: {
                fillColor: "#E8E5E5",
                textColor: "#000",
                halign: "right"
            }
        },


        // `${sub_qty}`,
    ];
    tableRows.push(footer);
    let printed = [
        {
            colSpan: 6,
            content: `Printed By ${getItem("userdata").user_id} / ${getToday()}`,
            styles: {
                // lineWidth: 0.1,
                fontStyle: "italic",
                textColor: "#000"
                // fillColor: "#E8E5E5"
            }
        }
    ];
    tableRows.push(printed);
    doc.autoTable({
        head: tableColumn,
        body: tableRows,
        // foot: footRows,
        startY: finalY,
        theme: "plain",
        rowPageBreak: "avoid",
        // pageBreak: "avoid",
        margin: { top: 10 },
        bodyStyles: {
            // lineWidth: 0.02,
            // lineColor: "#000",
            fontSize: 8
        },
        headStyles: {
            fontSize: 8,
            // lineWidth: 0.02,
            // lineColor: "#000",
            textColor: "#000",
            fillColor: "#E8E5E5"
        }
    });
    finalY = doc.autoTableEndPosY() + 3;
    tableRows = [];

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 10; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
            align: "center"
        });
    }
    var string = doc.output("datauristring");
    var x = window.open();
    x.document.open();
    x.document.write(
        "<html><head><title>Laporan Penjualan Rekap</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
        string +
        "></embed></body></html>"
    );
};

export default LaporanPenjualanTaxPurposePdf;
