import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    getUserdata,
    // getItem
} from "../../../../../components/helpers/function";

let col = 8;

class LaporanPenjualanRekapExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        console.log(this.props.data, "DATE_TRX")
        let jmlPenjual = 0;
        let jmlPengganti = 0;
        let jmlOngkos = 0;
        let jmlDpp = 0;
        let jmlPpn = 0;

        this.props.data.forEach((dataJual, index) => {
            jmlPenjual = jmlPenjual + dataJual.ongkos;
            jmlPengganti = jmlPengganti + dataJual.harga_total;
            jmlOngkos = jmlOngkos + dataJual.total_24k;
            jmlDpp = jmlDpp + dataJual.dpp;
            jmlPpn = jmlPpn + dataJual.ppn;
        });
        return (
            <>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-success btn-block"
                    table="table-to-xls"
                    filename="Laporan Penjualan Rekap"
                    sheet="Laporan Penjualan Rekap"
                    buttonText="Export Excel"
                />
                <table id="table-to-xls" style={{ display: "none" }}>
                    <thead>
                        <tr>
                            <th colSpan={col} style={{ textAlign: "center" }}>
                                {" "}
                                LAPORAN PENJUALAN REKAP{" "}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={col}> Periode </th>
                        </tr>
                        <tr>
                            <th colSpan={col}>
                                {" "}

                                {this.props.filter.tgl_from + " s/d " + this.props.filter.tgl_to}


                            </th>
                        </tr>
                        <tr>
                            <th colSpan="8"> TOKO : {getUserdata().nama_toko} </th>
                        </tr>

                        <tr>
                            <th colSpan="8"> ALAMAT : {getUserdata().alamat_toko} </th>
                        </tr>

                        <tr>
                            <th colSpan="8"></th>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                TANGGAL
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                PENJUAL
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                PENGGANTI
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                ONGKOS
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                DPP
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                                PPN
                            </td>
                        </tr>
                    </thead>
                    <tbody>

                        {this.props.data?.map((row, index) => (
                            <tr key={index}>
                                <td>{row._id}</td>
                                <td style={{ textAlign: "right" }}>
                                    &nbsp;{row.harga_total?.toLocaleString("kr-KO")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    &nbsp;{row.total_24k.toLocaleString("kr-KO")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    &nbsp;{row.harga_total?.toLocaleString("kr-KO")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    &nbsp;{row.dpp.toLocaleString("kr-KO")}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                    &nbsp;{row.ppn.toLocaleString("kr-KO")}
                                </td>

                            </tr>
                        ))}
                        <tr>
                            {/* <td colSpan="2" style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlPenjual}
                            </td> */}
                            <td colSpan="2" style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlPengganti.toLocaleString("kr-KO")}
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlOngkos.toLocaleString("kr-KO")}
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlPengganti.toLocaleString("kr-KO")}
                            </td>

                            <td style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlDpp.toLocaleString("kr-KO")}
                            </td>
                            <td style={{ backgroundColor: "#E8E5E5", color: "#000", textAlign: "right" }}>
                                {jmlPpn.toLocaleString("kr-KO")}
                            </td>

                        </tr>

                    </tbody>
                </table>
            </>
        );
    }
}
export default LaporanPenjualanRekapExcel;
