
import { getData } from "../config/axios";


export const GET_DATA_MEMBER = "GET_DATA_MEMBER";
export const SHOW_MODAL_EDIT_MEMBER = "SHOW_MODAL_EDIT_MEMBER";
export const SHOW_MODAL_TUKAR_POIN = "SHOW_MODAL_TUKAR_POIN";

export const getDataMember = () => {
    return (dispatch) => {
        getData('member/get/all').then((res) => {
            dispatch({
                type: GET_DATA_MEMBER,
                payload: {
                    data: res.data
                }
            })   
        }).catch((err) => {
            console.log(err);
        })
    }
}
  
export const showEditMember = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_EDIT_MEMBER,
        payload: {
          data: data,
        },
      });
    };
  }
export const ShowModalTukarPoin = (data) => {
    return (dispatch) => {
      dispatch({
        type: SHOW_MODAL_TUKAR_POIN,
        payload: {
          data: data,
        },
      });
    };
  }