const ListTokoKresno = [
  {
    kode_toko: "T3",
    nama_toko: "T3"
  },
  {
    kode_toko: "PG",
    nama_toko: "PG"
  },
  {
    kode_toko: "T2",
    nama_toko: "T2"
  },
  {
    kode_toko: "PST",
    nama_toko: "PST"
  },
  {
    kode_toko: "TK",
    nama_toko: "TK"
  },
  {
    kode_toko: "KG",
    nama_toko: "KG"
  },
  {
    kode_toko: "TTK",
    nama_toko: "TTK"
  }
];

export { ListTokoKresno };
