const ValidasiMasterBerlian = (value) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.kode_shape) {
      errors.kode_shape = "Kode Shape Harus Diisi";
    }
    if (!value.nama_shape) {
      errors.nama_shape = "Nama Shape Harus Diisi";
    }
    if (!value.kode_color) {
      errors.kode_color = "Kode Color Harus Diisi";
    }
    if (!value.nama_color) {
      errors.nama_color = "Nama Color Harus Diisi";
    }
 
    if (!value.kode_clarity) {
      errors.kode_clarity = "Kode Clarity Harus Diisi";
    }
    if (!value.nama_clarity) {
      errors.nama_clarity = "Nama Clarity Harus Diisi";
    }
  
    return errors;
  };
  
  export default ValidasiMasterBerlian;
  