// import React, { Suspense } from "react";
// import Skeleton from "react-loading-skeleton";
// import { Link, Route, withRouter } from "react-router-dom";
// import routes from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
// import access from "../../assets/accessDenied.svg";
// import { formatDate, getItem, getToday, getUserdata } from "../helpers/function.jsx";
// import Swal from "sweetalert2";
// import { postDataNoTImeOut } from "../../config/axios.jsx";
// import { ToastNotification } from "../helpers/notification.jsx";
// import moment from "moment";
// import { Loading } from "../helpers/library.jsx";
// function setTitle(path, routeArray,AppName) {
//   var pageTitle;
//   for (var i = 0; i < routeArray.length; i++) {
//     if (routeArray[i].path === path) {
//       pageTitle = AppName + " | " + routeArray[i].title.toUpperCase();
//     }
//   }
//   document.title = pageTitle === "undefined | LOGIN" ? "LOGIN" : pageTitle ? pageTitle : AppName;
// }
// // let menu = [];
// class Content extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoading: false,
//     };
//   }
//   componentDidMount() {
//     const AppName = getItem('userdata').nama_toko;
//     setTitle(this.props.history.location.pathname, routes,AppName);
//     // console.log(this.checkAcces());
//   }
//   UNSAFE_componentWillMount() {
//     this.props.history.listen(() => {
//     const AppName = getItem('userdata').nama_toko;
//       setTitle(this.props.history.location.pathname, routes,AppName);
//     });
//   }
//   showModalTutupToko() {
//     var datatoday = new Date();
//     var datatodays = datatoday.setDate(new Date(new Date(getUserdata().tgl_system)).getDate() + 1);
//     var todate = new Date(datatodays);
//     Swal.fire({
//       html:
//         "<h1><b>Apakah Anda Yakin Ingin  <br/> " +
//         "Menutup Toko" +
//         "</b> ?</h1><br>" +
//         "Dari Tanggal "+getUserdata().tgl_system + " Ke " + formatDate(moment(new Date(todate)).format("YYYY-MM-DD")),
//       icon: "warning",
//       position: "top-center",
//       cancelButtonText: "Tidak",
//       showCancelButton: true,
//       confirmButtonText: "OK",
//       showConfirmButton: true,
//     }).then((result) => {
//       if (result.isConfirmed) {
//         this.setState({
//           isLoading : true
//         })
//         postDataNoTImeOut("system/close", { PROSES: "close" })
//           .then((res) => {
//             ToastNotification("success", "Berhasil Menutup Toko");

//             setTimeout(() => {
//               // window.location.reload();
//               window.location.href = "/";
//               localStorage.clear();
//               localStorage.setItem("islogin", "false");
//               this.setState({
//                 isLoading : false
//               })
//             }, 1000);
//           })
//           .catch((err) => {
//             this.setState({
//               isLoading : false
//             })
//             // console.log(err.response);
//               err.response === undefined ? ToastNotification("info",err.response === undefined ? "Terjadi Kesalahan Saat Request Data" : err.response.data )
//               : err.response.data === "Invalid token." ? this.handleInvalidToken()
//               : 
//               ToastNotification("info",err.response.data)
//                 //   setTimeout(() => {
//                 //   window.location.reload();
//                 //   localStorage.clear();
//                 //   localStorage.setItem("islogin", "false");
//                 // }, 1000);
//           });
//       } else {
//         this.setState({
//           isLoading : false
//         })
//       }
//     });
//   }
//   handleInvalidToken() {
//     ToastNotification("info", "Toko Sudah Ditutup Oleh Akun Lain");

//     setTimeout(() => {
//       // window.location.reload();
//       window.location.href = "/";
//       localStorage.clear();
//       localStorage.setItem("islogin", "false");
//     }, 1000);
//   }
//   checkAcces() {
//     // let array = [
//     //   "/dashboard",
//     //   "/master-jenis",
//     //   "/data-users",
//     //   "/master-group",
//     //   "/master-gudang",
//     //   "/master-baki",
//     //   "/master-bandrol",
//     //   "/master-sales",
//     //   "/master-tukang",
//     //   "/master-kode-transaksi",
//     //   "/master-kondisi-barang",
//     //   "/master-kondisi-pembelian",
//     //   "/master-kondisi-pinjaman",
//     //   "/master-kadar",
//     //   "/data-barang",
//     //   "/terima-barang",
//     //   "/laporan-terima-barang",
//     //   "/blank",
//     //   "/laporan-barang-detail",
//     // ]; //ganti dengan hak akses dari API
//     // console.log(menu);
//     let feedback =
//       localStorage.getItem("menu") === null
//         ? []
//         : JSON.parse(localStorage.getItem("menu"));
//     // console.log(feedback);
//     let menu = [];
//     feedback.forEach((element) => {
//       menu.push(element.menu_name);
//     });
//     let data = this.props.history.location.pathname;
//     // console.log(data);
//     let hasil = menu.findIndex((res) => res === data);
//     return data === "/" || data === "/dashboard" ? 2 : hasil;
//   }
//   render() {
//     // console.log(getToday())
//     return (
//       <Suspense fallback={<Skeleton width={"100%"} height={1000} />}>
//         <PageSettings.Consumer>
//           {({
//             pageContentFullWidth,
//             pageContentClass,
//             pageContentInverseMode,
//           }) => (
//             <div
//               className={
//                 "content " +
//                 (pageContentFullWidth ? "content-full-width " : "") +
//                 (pageContentInverseMode ? "content-inverse-mode " : "") +
//                 pageContentClass
//               }
//             >
//               {this.checkAcces() === -1 ? (
//                 <>
//                   {localStorage.getItem("userdata") === null ? (-1) :
//                     JSON.parse(localStorage.getItem("userdata")).level === "OWN" || JSON.parse(localStorage.getItem("userdata")).level === "SU" ? (
//                       <>
//                         {getUserdata().tgl_system < getToday() ?
//                           <>
//                           {this.props.history.location.pathname === "/validasi-jual-beli" || this.props.history.location.pathname === "/validasi-hutang" ? (
//                               <>
//                                 {routes.map((route, index) => (
//                                   <Route
//                                     key={index}
//                                     path={route.path}
//                                     exact={route.exact}
//                                     component={route.component}
//                                   />
//                                 ))}
//                               </>
//                             ) : (
//                               <div className="container text-center mt-5 ">
//                                 <div className="align-item-center">
//                                   <img src={access} alt="Access" width="30%" />
//                                   <h1> Mohon Maaf</h1>
//                                   <h3 className="f-w-900">
//                                     Tanggal System Berbeda <br />
//                                     Dengan Tanggal Sekarang
//                                   </h3>
//                                   <h5>Silahkan Tutup Toko</h5>
//                                   <div>
//                                     <button
//                                       disabled={this.state.isLoading}
//                                       onClick={() => this.showModalTutupToko()}
//                                       className="btn btn-primary mt-3"
//                                     >
//                                       {this.state.isLoading ? (
//                                         <>
//                                           <i className="fas fa-spinner fa-spin"></i>{" "}
//                                           &nbsp; Tutup Toko Sekarang
//                                         </>
//                                       ) : (
//                                         <>
//                                           {" "}
//                                           <i className="fa fa-window-close mr-3"></i>{" "}
//                                           Tutup Toko Sekarang{" "}
//                                         </>
//                                       )}
//                                     </button>
//                                   </div>
//                                 </div>
//                               </div>
//                             )}
//                           </>
//                         : <>
//                         {routes.map((route, index) => (
//                           <Route
//                             key={index}
//                             path={route.path}
//                             exact={route.exact}
//                             component={route.component}
//                           />
//                         ))}
//                       </>
//                         }
//                     </>
//                   ) : (
//                     <div className="container text-center mt-5 ">
//                       <div className="align-item-center">
//                         <img src={access} alt="Access" width="30%" />
//                         <h1> Mohon Maaf</h1>
//                         <h1 className="f-w-900">
//                           Akses Di Menu Ini Tidak Di Izinkan
//                         </h1>
//                         <h5>Hubungi Admin Jika Ingin Memakai Menu Ini</h5>
//                         <div>
//                           <Link to="/dashboard">
//                             <button className="btn btn-primary mt-3">
//                               <i className="fa fa-chevron-left mr-3"></i> Go
//                               Home
//                             </button>
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </>
//               ) : localStorage.getItem("userdata") === null || undefined ? (
//                 <>
//                   {routes.map((route, index) => (
//                     <Route
//                       key={index}
//                       path={route.path}
//                       exact={route.exact}
//                       component={route.component}
//                     />
//                   ))}
//                 </>
//               ) : getUserdata().tgl_system < getToday() ? (
//                 <>
//                   {this.props.history.location.pathname === "/validasi-jual-beli" || this.props.history.location.pathname === "/validasi-hutang" ? (
//                     <>
//                       {routes.map((route, index) => (
//                         <Route
//                           key={index}
//                           path={route.path}
//                           exact={route.exact}
//                           component={route.component}
//                         />
//                       ))}
//                     </>
//                   ) : (
//                     <div className="container text-center mt-5 ">
//                       <div className="align-item-center">
//                         <img src={access} alt="Access" width="30%" />
//                         <h1> Mohon Maaf</h1>
//                         <h3 className="f-w-900">
//                           Tanggal System Berbeda <br />
//                           Dengan Tanggal Sekarang
//                         </h3>
//                         <h5>Silahkan Tutup Toko</h5>
//                         <div>
//                           <button
//                             disabled={this.state.isLoading}
//                             onClick={() => this.showModalTutupToko()}
//                             className="btn btn-primary mt-3"
//                           >
//                             {this.state.isLoading ? (
//                               <>
//                                 <i className="fas fa-spinner fa-spin"></i>{" "}
//                                 &nbsp; Tutup Toko Sekarang
//                               </>
//                             ) : (
//                               <>
//                                 {" "}
//                                 <i className="fa fa-window-close mr-3"></i>{" "}
//                                 Tutup Toko Sekarang{" "}
//                               </>
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </>
//               ) : (
//                 <>
//                   {routes.map((route, index) => (
//                     <Route
//                       key={index}
//                       path={route.path}
//                       exact={route.exact}
//                       component={route.component}
//                     />
//                   ))}
//                 </>
//               )}
//             </div>
//           )}
//         </PageSettings.Consumer>
//         {this.state.isLoading ? (
//         <Loading 
//         loading
//         background="rgba(0, 0, 0, 0.35)"
//          loaderColor="rgba(94, 147, 117, 1)" />
//       ) : null}
//       </Suspense>
//     );
//   }
// }

// export default withRouter(Content);



import {
  // formatDate,
  Suspense,
  getItem,
  getToday,
  getUserdata,
  Loading,
  io,
  postDataNoTImeOut,
  ToastNotification,
  Swal,
  // moment,
  routes,
  setTitle,
  server,
  url,
  // Modal,
  Skeleton,
  React,
  Route, withRouter,
  convertDate,
  Modal, ModalBody, Progress
} from "../helpers/library";

import TglSystemBerbeda from "./TglSystemBerbeda.jsx";
import AksesTidakDiizinkan from "./AksesTidakDiizinkan.jsx";

import { addOneDay } from "../helpers/function.jsx";



// import { addOneDay } from "components/helpers/function";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      progress: {}
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  componentDidMount() {
    const AppName = getItem("userdata").nama_toko;
    if (this.props.history.location.pathname === "/privacy-policy") {
      document.title = "Privacy Policy";
    } else if (
      this.props.history.location.pathname === "/terms-and-conditions"
    ) {
      document.title = "Terms And Conditions";
    } else {
      setTitle(this.props.history.location.pathname, routes, AppName);
    }
  }
  UNSAFE_componentWillMount() {
    this.props.history.listen(() => {
      const AppName = getItem("userdata").nama_toko;
      if (this.props.history.location.pathname === "/privacy-policy") {
        document.title = "Privacy Policy";
      } else if (
        this.props.history.location.pathname === "/terms-and-conditions"
      ) {
        document.title = "Terms And Conditions";
      } else {
        setTitle(this.props.history.location.pathname, routes, AppName);
      }
    });
  }

  toggleModal(name) {
    switch (name) {
      case "modalDialog":
        this.setState({ modalDialog: !this.state.modalDialog });
        break;
      case false:
        this.setState({ modalDialog: false });
        break;
      default:
        break;
    }
  }

  showModalTutupToko() {
    Swal.fire({
      html:
        "<h1><b>Apakah Anda Yakin Ingin  <br/> " +
        "Menutup Toko" +
        "</b> ?</h1><br>" +
        "Dari Tanggal " +
        getUserdata().tgl_system +
        " Ke " +
        // formatDate(moment(new Date(todate)).format("YYYY-MM-DD")),
        convertDate(
          addOneDay(new Date(getUserdata().tgl_system)),
          "DD-MM-YYYY"
        ),
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          isLoading: true
        });
        this.setState({
          modal: "TUTUP_TOKO"
        });
        this.toggleModal("modalDialog");
        this.socket = io.connect(server.replace("/api/v1/", ""));
        let selft = this;
        this.socket.emit("start-closed-store", () => { });
        this.socket.on(`closed-store-progress`, (data) => {
          selft.setState({
            progress: data
          });
        });

        postDataNoTImeOut("system/close", { PROSES: "close" })
          .then((res) => {
            ToastNotification("success", "Berhasil Menutup Toko");

            setTimeout(() => {
              // window.location.reload();
              window.location.href = url;
              localStorage.clear();
              localStorage.setItem("islogin", "false");
              this.setState({
                isLoading: false
              });
            }, 1000);
          })
          .catch((err) => {
            this.toggleModal(false);
            this.setState({
              isLoading: false
            });
            if (err?.response?.status === 422) {
              this.setState({
                modal: "TUTUP_TOKO"
              });
              this.toggleModal("modalDialog");
            }
            err?.response?.data === "Invalid token."
              ? this.handleInvalidToken()
              : err?.response?.data === "Error! Sedang dalam proses tutup toko!"
                ? this.handleInvalidToken()
                : ToastNotification(
                  "info",
                  err?.response?.data || "Terjadi kesalahan saat merequest data"
                );
          });
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }
  handleInvalidToken() {
    ToastNotification("info", "Toko Sudah Ditutup Oleh Akun Lain");
    setTimeout(() => {
      window.location.href = url;
      localStorage.clear();
      localStorage.setItem("islogin", "false");
    }, 1000);
  }
  checkAcces() {
    let feedback = getItem("menu") === null ? [] : getItem("menu");
    let menu = [];
    feedback.forEach((row) => {
      menu.push(row.path);
      if (row.children) {
        row.children.forEach((el) => {
          if (el.children) {
            el.children.forEach((el2) => {
              menu.push(el2.path);
            });
          }
          menu.push(el.path);
        });
      }
    });
    let data = this.props.history.location.pathname;
    let hasil = menu.findIndex((res) => res === data);
    return data === "/" ||
      // data === "/dashboard" ||
      data === "/home" ||
      data === "/SubMenuPenjualan" ||
      data === "/SubMenuPembelian" ||
      data === "/SubMenuPesanan" ||
      data === "/SubMenuService" ||
      data === "/SubMenuHutang" ||
      data === "/SubMenuLaporan" ||
      data === "/SubMenuMember" ||
      data === "/SubMenuUtility"
      ? 2
      : hasil;
  }
  render() {
    return (
      <Suspense fallback={<Skeleton width={"100%"} height={1000} />}>
        <PageSettings.Consumer>
          {({
            pageContentFullWidth,
            pageContentClass,
            pageContentInverseMode
          }) => (
            <div
              className={
                "content " +
                (pageContentFullWidth ? "content-full-width " : "") +
                (pageContentInverseMode ? "content-inverse-mode " : "") +
                pageContentClass
              }
            >
              <Modal isOpen={this.state.modalDialog} centered>
                <ModalBody>
                  <div className="text-center">
                    <h2>
                      Mohon Menunggu <br /> Sedang Menutup Toko !!!
                    </h2>
                    <br />
                    <Progress
                      animated
                      value={this.state.progress.percentage}
                      max="100"
                      barClassName="custome"
                    >
                      {this.state.progress.percentage}%
                    </Progress>
                    {this.state.progress.message}
                  </div>
                </ModalBody>
              </Modal>
              {this.checkAcces() === -1 ? (
                <>
                  {getItem("userdata").level === "OWN" ||
                    getItem("userdata").level === "SU" ||
                    getItem("userdata").level === "MU" ? (
                    <>
                      {getUserdata().tgl_system < getToday() ? (
                        <>
                          {this.props.history.location.pathname ===
                            "/validasi-jual-beli" ||
                            this.props.history.location.pathname ===
                            "/stock-opname-global" ||
                            this.props.history.location.pathname ===
                            "/setting-nota" ||
                            this.props.history.location.pathname ===
                            "/settingsystem" ||
                            this.props.history.location.pathname ===
                            "/validasi-hutang" ||
                            this.props.history.location.pathname ===
                            "/privacy-policy" ? (
                            <>
                              {routes.map((route, index) => (
                                <Route
                                  key={index}
                                  path={route.path}
                                  exact={route.exact}
                                  component={route.component}
                                />
                              ))}
                            </>
                          ) : (
                            <TglSystemBerbeda
                              isLoading={this.state.isLoading}
                              showModalTutupToko={() =>
                                this.showModalTutupToko()
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {routes.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={route.component}
                            />
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <AksesTidakDiizinkan />
                  )}
                </>
              ) : getItem("userdata") === null || undefined ? (
                <>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                </>
              ) : getUserdata().tgl_system < getToday() ? (
                <>
                  {this.props.history.location.pathname ===
                    "/validasi-jual-beli" ||
                    this.props.history.location.pathname ===
                    "/stock-opname-global" ||
                    this.props.history.location.pathname === "/settingsystem" ||
                    this.props.history.location.pathname === "/setting-nota" ||
                    this.props.history.location.pathname ===
                    "/validasi-hutang" ? (
                    <>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      ))}
                    </>
                  ) : (
                    <TglSystemBerbeda
                      isLoading={this.state.isLoading}
                      showModalTutupToko={() => this.showModalTutupToko()}
                    />
                  )}
                </>
              ) : (
                <>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                </>
              )}
            </div>
          )}
        </PageSettings.Consumer>
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </Suspense>
    );
  }
}

export default withRouter(Content);
