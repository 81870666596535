import { setItem } from "../components/helpers/function";
import { getData, getData2 } from "../config/axios";

//Terima Barang
export const GET_NO_KIRIM = "GET_NO_KIRIM";
export const SHOW_MODAL_TERIMA_BARANG = "SHOW_MODAL_TERIMA_BARANG";
export const SET_CAMERA = "SET_CAMERA";
export const SET_BARANG_PINDAH = "SET_BARANG_PINDAH";
export const SET_BARANG_PINDAH_SATU_BAKI = "SET_BARANG_PINDAH_SATU_BAKI";
export const SET_HANCUR_BARANG = "SET_HANCUR_BARANG";
export const SHOW_MODAL_EDIT = "SHOW_MODAL_EDIT";
export const SHOW_MODAL_PESANAN = "SHOW_MODAL_PESANAN";
export const GET_TIMBANG_BARANG = "GET_TIMBANG_BARANG";
export const UPDATE_TIMBANG_BARANG = "UPDATE_TIMBANG_BARANG";
export const REPORT_AUTHORIZATION = "REPORT_AUTHORIZATION";
export const GET_BARANG_BARCODE = "GET_BARANG_BARCODE";

export const FORM_MODAL = "FORM_MODAL";



export const FormModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: FORM_MODAL,
      payload: {
        data: data,
      },
    });
  };
};
export const ShowModalTerimaBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_TERIMA_BARANG,
      payload: {
        data: data,
      },
    });
  };
};


export const setCamera = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMERA,
      payload: {
        data: data,
      },
    });
  };
};
export const showEditBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_EDIT,
      payload: {
        data: data,
      },
    });
  };
};
export const showModalPesanan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL_PESANAN,
      payload: {
        data: data,
      },
    });
  };
};
export const setBarangPindah = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PINDAH,
      payload: {
        data: data,
      },
    });
  };
};
export const setHancurBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HANCUR_BARANG,
      payload: {
        data: data,
      },
    });
  };
};
export const setBarangPindahSatuBaki = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_PINDAH_SATU_BAKI,
      payload: {
        data: data,
      },
    });
  };
};

export const getNokirim = (data) => {
  return (dispatch) => {
    getData("kirimbarang/get/nokirim")
      .then((res) => {
        dispatch({
          type: GET_NO_KIRIM,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getTimbangBarang = (gudang, baki, tanggal) => {
  return (dispatch) => {
    getData2(`timbang-baki?tanggal=${tanggal}&kode_gudang=${gudang}&kode_toko=${baki}`)
      .then((res) => {
        setItem("timbang_barang", res.data)
        dispatch({
          type: GET_TIMBANG_BARANG,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const updateTimbangBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TIMBANG_BARANG,
      payload: {
        data: data,
      },
    });
  };
};


export const getLaporanOtorisasi = (tgl_awal, tgl_akhir) => {
  return (dispatch) => {
    getData2(`authorization/reports?tanggal[gte]=${tgl_awal}&tanggal[lte]=${tgl_akhir}`)
      .then((res) => {
        dispatch({
          type: REPORT_AUTHORIZATION,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getListBarangBarcode = (kode_gudang, kode_toko) => {
  return (dispatch) => {
    getData2(`tambah-barang?kode_gudang=${kode_gudang || "ALL"}&kode_toko=${kode_toko || "ALL"}`)
      .then((res) => {
        dispatch({
          type: GET_BARANG_BARCODE,
          payload: {
            data: res.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};