import {
    PanelContent,
    reduxForm,
    useDispatch,
    React,
    useSelector,
    selectorMaster
} from "../../../../components/helpers/library";
import { cetakLaporanPdf } from "./Redux";
import Form from "./Form";
import LaporanPenjualanRekapExcel from "./excel/LaporanPenjualanRekapExcel";
import LaporanPenjualanTaxPurposePdf from "./pdf";

let LaporanPenjualanTaxPurpose = () => {
    const dispatch = useDispatch();

    const datattmp = useSelector(selectorMaster.datatmp)
    return (
        <PanelContent menu="Laporan Penjualan Rekap" submenu="Laporan Penjualan">
            <Form onSubmit={(data) => dispatch(cetakLaporanPdf(data))} />
            <div className={`${datattmp.length === 0 ? 'd-none' : 'd-block'}`}>
                <div className="row">
                    <div className="col-lg-6">
                        <button
                            type="button"
                            onClick={() => LaporanPenjualanTaxPurposePdf(datattmp?.data, datattmp.filter)}
                            className="btn btn-warning btn-block"
                        >
                            {" "}
                            Export Pdf{" "}
                        </button>
                    </div>
                    <div className="col-lg-6">
                        {datattmp?.data !== undefined ?
                            <LaporanPenjualanRekapExcel
                                data={datattmp?.data}
                                filter={datattmp.filter}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        </PanelContent>
    );
};


LaporanPenjualanTaxPurpose = reduxForm({
    form: "LaporanPenjualanTaxPurpose",
    enableReinitialize: true
})(LaporanPenjualanTaxPurpose);
export default LaporanPenjualanTaxPurpose;
