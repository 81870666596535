import React from 'react'
import access from "../../assets/accessDenied.svg";

const TglSystemBerbeda = (props) => {
    return (
        <div className="container text-center mt-5 ">
            <div className="align-item-center">
                <img src={access} alt="Access" width="30%" />
                <h1> Mohon Maaf</h1>
                <h3 className="f-w-900">
                    Tanggal System Berbeda <br />
                    Dengan Tanggal Sekarang
                </h3>
                <h5>Silahkan Tutup Toko</h5>
                <div>
                    <button
                        disabled={props.isLoading}
                        onClick={() => props.showModalTutupToko()}
                        className="btn btn-primary mt-3"
                        type='button'
                    >
                        {props.isLoading ? (
                            <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                                &nbsp; Tutup Toko Sekarang
                            </>
                        ) : (
                            <>
                                {" "}
                                <i className="fa fa-window-close mr-3"></i>{" "}
                                Tutup Toko Sekarang{" "}
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TglSystemBerbeda
