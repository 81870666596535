import {
    Field,
    React,
    connect,
    reduxForm,
    Loading,
    ReanderField,
    hideModal,
    HiidenFiled,
  } from "../../../../components/helpers/library";
import ValidasiMasterBerlian from "../../../../Validasi/ValidasiMasterBerlian";
  
  const maptostate = (state) => {
    return {
      initialValues: {
        id: state.datamaster.ShowDataModal.id,
        jenis_batu: state.datamaster.ShowDataModal.jenis_batu,
        nama_batu: state.datamaster.ShowDataModal.nama_batu,
      },
    };
  };
  class HeadJenisBatu extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit() {}
  
    componentDidMount() {
      setTimeout(() => {
        if (this.props.isEdit) {
          document.getElementById("nama_batu").focus();
        } else {
          document.getElementById("jenis_batu").focus();
        }
      }, 100);
    }
  
    render() {
      return (
        <form
          onSubmit={this.props.handleSubmit}
          autoComplete="off"
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >

          <Field
            id="id"
            tabIndex="1"
            name="id"
            component={HiidenFiled}
            type="hidden"
          />
          <Field
            id="jenis_batu"
            tabIndex="1"
            name="jenis_batu"
            component={ReanderField}
            type="text"
            label="Jenis Batu"
            readOnly={this.props.isEdit}
            placeholder="Masukan Jenis Batu"
          />
          <Field
            id="nama_batu"
            tabIndex="2"
            name="nama_batu"
            component={ReanderField}
            type="text"
            label="Nama Batu"
            placeholder="Masukan Nama Batu"
            onBlur={() => this.setState({ aktif: true })}
          />
  
          <div className="row">
            <div className="col-12 text-right">
              <button
                className="btn btn-white"
                onClick={() => this.props.dispatch(hideModal())}
                type="button"
                disabled={this.props.isLoading}
              >
                Batal
              </button>
              &nbsp;
              <button
                tabIndex="3"
                className={this.state.aktif ? "btn btn-primary" : "btn btn-white"}
                disabled={this.props.submitting}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  "Simpan Data"
                )}
              </button>
            </div>
          </div>
          {this.props.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)"
            />
          ) : null}
        </form>
      );
    }
  }
  
  HeadJenisBatu = reduxForm({
    form: "HeadJenisBatu",
    enableReinitialize: true,
    validate : ValidasiMasterBerlian
  })(HeadJenisBatu);
  export default connect(maptostate)(HeadJenisBatu);
  