import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { reduxForm } from "redux-form";
import SidebarNavList from "./sidebar-nav-list.jsx";
import menus2 from './menu.jsx';
import { getItem } from "../helpers/function.jsx";

const maptostate = () => {
	const menus =
		getItem("menu") === null
			? []
			: getItem("menu");
	return {
		menu: getItem("userdata").level === "OWN" || getItem("userdata").level === "SU" || getItem("userdata").level === "MU" ? menus2 : menus,
	};
};
class SidebarNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: -1,
			clicked: -1,
			status: false,
		};
	}

	handleExpand(e, i, match) {
		e.preventDefault();

		if (this.state.clicked === -1 && match) {
			this.setState((state) => ({
				active: -1,
				clicked: 1,
			}));
		} else {
			this.setState((state) => ({
				active: this.state.active === i ? -1 : i,
				clicked: 1,
			}));
		}
	}

	render() {
		return (
			<ul className="nav">
				<li className="nav-header">Navigation</li>
				{this.props.menu.map((menu, i) => (
					<Route
						path={menu.path}
						exact={menu.exact}
						key={i}
						children={({ match }) => (
							<SidebarNavList
								data={menu}
								key={i}
								expand={(e) => this.handleExpand(e, i, match)}
								active={i === this.state.active}
								clicked={this.state.clicked}
							/>
						)}
					/>
				))}
			</ul>
		);
	}
}

// export default SidebarNav;

SidebarNav = reduxForm({
	form: "SidebarNav",
	enableReinitialize: true,
})(SidebarNav);
export default connect(maptostate)(SidebarNav);
