import React, { lazy } from "react";
import JenisBatu from "../pages/DataMaster/Berlian/JenisBatu/index.jsx";
import HakAksesNews from "../pages/HakAksesNews.jsx";
import ImportData from "../pages/ImportData/index.jsx";
import LaporanPenjualanTaxPurpose from "../pages/Laporan/LaporanPenjualan/PenjualanRekap/index.jsx";
import DelteDataTrancate from "../pages/deleteData/index.jsx";

const PenerimaanBaarang = lazy(() =>
  import("../pages/Barang/PenerimaanBaarang/index.jsx")
);
const MasterBank = lazy(() =>
  import("../pages/DataMaster/MasterBank/index.jsx")
);
const MasterRekening = lazy(() =>
  import("../pages/DataMaster/MasterRekening/index.jsx")
);
const LaporanTerimaBarangSupplier = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanTerimaBarangSupplier/index.jsx")
);
const LaporanPenjualanMargin = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanMargin/index.jsx")
);
const StockOpnamePenerimaan = lazy(() =>
  import("../pages/StockOpname/StockOpnamePenerimaan/index.jsx")
);
const LihatUangKas = lazy(() =>
  import("../pages/UangKas/LihatUangKas/index.jsx")
);
const BukiBerlian = lazy(() =>
  import("../pages/Laporan/LaporanBerlian/BukuBerlian")
);

const BatuBerlian = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuBerlian/index.jsx")
);
const BatuCutgrade = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuCutgrade/index.jsx")
);
const BatuPolish = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuPolish/index.jsx")
);
const BatuSymetry = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuSymetry/index.jsx")
);
const BatuFluorencence = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuFluorencence/index.jsx")
);
const BatuClarity = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuClarity/index.jsx")
);
const BatuColor = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuColor/index.jsx")
);
const BatuShape = lazy(() =>
  import("../pages/DataMaster/Berlian/BatuShape/index.jsx")
);
const FormhargaEmas = lazy(() =>
  import("../pages/DataMaster/ParameterHargaEmas/FormhargaEmas.jsx")
);
const FormParamterPoint = lazy(() =>
  import("../pages/DataMaster/ParameterPoint/FormParamterPoint.jsx")
);
const FormLihatHutang = lazy(() =>
  import("../pages/Hutang/LihatHutang/FormLihatHutang.jsx")
);
const FormTransaksiHutang = lazy(() =>
  import("../pages/Hutang/TransaksiHutang/FormTransaksiHutang.jsx")
);
const FormValidasiHutang = lazy(() =>
  import("../pages/Hutang/ValidasiHutang/FormValidasiHutang.jsx")
);
const FormValidasiJualBeli = lazy(() =>
  import("../pages/Kasir/ValidasiJualBeli/FormValidasiJualBeli.jsx")
);
const FormValidasiTitipan = lazy(() =>
  import("../pages/Kasir/ValidasiTitipan/FormValidasiTitipan.jsx")
);
const FormLaporanBarangSumary = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanBarangSumary/FormLaporanBarangSumary.jsx")
);
const FormLaporanEod = lazy(() =>
  import("../pages/Laporan/LaporanEod/FormLaporanEod.jsx")
);
const FormLaporanBatalHutang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanBatalHutang/FormLaporanBatalHutang.jsx")
);
const FormLaporanHutangLunas = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanHutangLunas/FormLaporanHutangLunas.jsx")
);
const FormLaporanHutang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanTransaksiHutang/FormLaporanHutang.jsx")
);
const FormLaporanMember = lazy(() =>
  import("../pages/Laporan/LaporanMember/FormLaporanMember.jsx")
);
const FormLaporanBatalBeli = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanBatalPembelian/FormLaporanBatalBeli.jsx")
);
const FormLaporanBatalService = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanBatalService/FormLaporanBatalService.jsx")
);
const FormLaporanServiceAmbil = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanServiceAmbil/FormLaporanServiceAmbil.jsx")
);
const FormLaporanService = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanTransaksiService/FormLaporanService.jsx")
);
const FormHakAkses = lazy(() =>
  import("../pages/ManageUsers/HakAkses/FormHakAkses.jsx")
);
const DataMember = lazy(() => import("../pages/Member/DataMember.jsx"));
const LihatTransaksiMember = lazy(() =>
  import("../pages/Member/LihatTransaksiMember/LihatTransaksiMember.jsx")
);
const FormTransaksiPoin = lazy(() =>
  import("../pages/Member/TransaksiPoin/FormTransaksiPoin.jsx")
);
const FormTukarPoin = lazy(() =>
  import("../pages/Member/TukarPoin/FormTukarPoin.jsx")
);
const FormLihatPembelian = lazy(() =>
  import("../pages/Pembelian/LihatPembelian.jsx/FormLihatPembelian.jsx")
);
const BatalTitipan = lazy(() =>
  import("../pages/Penjualan/BatalTitipan/index.jsx")
);
const FormLihatPenjualan = lazy(() =>
  import("../pages/Penjualan/LihatPenjualan/FormLihatPenjualan.jsx")
);
const FormValidasiPenjualan = lazy(() =>
  import("../pages/Penjualan/ValidasiPenjualan/FormValidasiPenjualan.jsx")
);
const Pesanan = lazy(() => import("../pages/Pesanan/index.jsx"));
const LihatPesanan = lazy(() =>
  import("../pages/Pesanan/LihatPesanan/index.jsx")
);
const LihatServices = lazy(() =>
  import("../pages/Service/LihatServices/LihatServices.jsx")
);
const FormTransaksiServces = lazy(() =>
  import("../pages/Service/TransaksiServices/FormTransaksiServces.jsx")
);
const FormStockOpname = lazy(() =>
  import("../pages/StockOpname/FormStockOpname.jsx")
);
const SettingNota = lazy(() =>
  import("../pages/utility/SettingNota/index.jsx")
);
const SettingSystem = lazy(() =>
  import("../pages/utility/SettingSystem/SettingSystem.jsx")
);
const FormLaporanTambahBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/FormLaporanTambahBarang/FormLaporanTambahBarang.jsx")
);
const LaporanNonCash = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/LaporanNonCash/index.jsx")
);
const LaporanOtorisasi = lazy(() =>
  import("../pages/Laporan/LaporanOtorisasi/index.jsx")
);
const TimbangBarang = lazy(() =>
  import("../pages/Barang/TimbangBarang/TimbangBarang.jsx")
);
const FormLaporanTimbangBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/FormLaporanTimbangBarang/FormLaporanTimbangBarang.jsx")
);
const CetakBarcode = lazy(() =>
  import("../pages/utility/CetakBarcode/CetakBarcode.jsx")
);
const FormLaporanPenjualanTukar = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanTukar/FormLaporanPenjualanTukar.jsx")
);
const FormLaporanPembelianBarangHistory = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanPembelianBarangHistory/FormLaporanPembelianBarangHistory.jsx")
);
const Cucian = lazy(() => import("../pages/Cucian/index.jsx"));
const PindahSortir = lazy(() =>
  import("../pages/Cucian/PindahSortir/index.jsx")
);
const SettingToko = lazy(() =>
  import("../pages/utility/SettingToko/index.jsx")
);

const DataMareketPlace = lazy(() =>
  import("../pages/DataMaster/DataMarketPlace")
);
const LaporanPenjualanMarketPlace = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanMarketPlace/index.jsx")
);
const LaporanStockJual = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanStockJual/index.jsx")
);
const LaporanServiceMasuk = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanServiceMasuk/index.jsx")
);
const LaporanServiceSelesai = lazy(() =>
  import("../pages/Laporan/LaporanService/LaporanServiceSelesai/index")
);
const LaporanStockHutang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanStockHutang/index.jsx")
);
const LaporanTitipUang = lazy(() =>
  import("../pages/Laporan/LaporanHutang/LaporanTitipUang/index.jsx")
);
const LaporanPesanan = lazy(() =>
  import("../pages/Laporan/LaporanPesanan/index.jsx")
);
const TambahUangBank = lazy(() =>
  import("../pages/UangKas/TambahUangBank/index.jsx")
);
const AmbilUangBank = lazy(() =>
  import("../pages/UangKas/AmbilUangBank/index.jsx")
);
const TerimaBarang = lazy(() =>
  import("../pages/Barang/TerimaBarang/TerimaBarang.jsx")
);
const FormLaporanTerimaBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanTerimaBarang/FormLaporanTerimaBarang.jsx")
);
const BlankPages = lazy(() => import("../pages/BlankPages.jsx"));

const LaporanKeuanganKas = lazy(() =>
  import("../pages/Laporan/LaporanKeuangan/Kas/LaporanKeuanganKas.jsx")
);
const ImportDataPusat = lazy(() =>
  import("../pages/ManageData/ImportDataPusat/ImportDataPusat.jsx")
);
const KirimDataBarang = lazy(() =>
  import("../pages/ManageData/KirimBarangKeCabang/KirimDataBarang.jsx")
);
const FormPindahBarang = lazy(() =>
  import("../pages/Barang/PindahBarang/FormPindahBarang.jsx")
);
const FormLaporanPindahBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanPindahBarang/FormLaporanPindahBarang.jsx")
);
const FormPindahBarangSatuBaki = lazy(() =>
  import("../pages/Barang/PindahBarangSatuBaki/FormPindahBarangSatuBaki.jsx")
);

//Penjualan
const FormTransaksiPenjualan = lazy(() =>
  import("../pages/Penjualan/TransaksiPenjualan/FormTransaksiPenjualan.jsx")
);
const FormLaporanPenjualanBarang = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanPenjualanBarang/FormLaporanPenjualanBarang.jsx")
);
const FormLaporanPenjualanSales = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/PenjualanSales/FormLaporanPenjualanSales.jsx")
);
const FormLaporanPenjualanBayar = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/PenjualanBayar/FormLaporanPenjualanBayar.jsx")
);
const FormBatalPenjualan = lazy(() =>
  import("../pages/Penjualan/BatalPenjualan/FormBatalPenjualan.jsx")
);
const FormLaporanBatalPenjualan = lazy(() =>
  import("../pages/Laporan/LaporanPenjualan/LaporanBatalPenjualan/FormLaporanBatalPenjualan.jsx")
);

//End Penjualan

//Pembelian
const FormLaporanPembelianSales = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/PembelianSales/FormLaporanPembelianSales.jsx")
);
const FormTransaksiPembelian = lazy(() =>
  import("../pages/Pembelian/TransaksiPembelian/FormTransaksiPembelian.jsx")
);
const FormLaporanPembelianBarang = lazy(() =>
  import("../pages/Laporan/LaporanPembelian/LaporanPembelianBarang/FormLaporanPembelianBarang.jsx")
);
const FormBatalPembelian = lazy(() =>
  import("../pages/Pembelian/BatalPembelian/FormBatalPembelian.jsx")
);
// End Pembelian

//Barang
const TambahBarang = lazy(() =>
  import("../pages/Barang/TambahBarang/TambahBarang.jsx")
);
const FormHancurBarang = lazy(() =>
  import("../pages/Barang/HancurDataBarang/FormHancurBarang.jsx")
);
const DataBarang = lazy(() =>
  import("../pages/Barang/DataBarang/DataBarang.jsx")
);
const FormDetailBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/FormLaporanDetailBarang/FormDetailBarang.jsx")
);
const FormLaporanHancurBarang = lazy(() =>
  import("../pages/Laporan/LaporanBarang/LaporanHancurBarang/FormLaporanHancurBarang.jsx")
);
// End Barang

//Data Master
const MasterGroup = lazy(() =>
  import("../pages/DataMaster/DataGroup/MasterGroup.jsx")
);
const MasterKadar = lazy(() =>
  import("../pages/DataMaster/DataKadar/MasterKadar.jsx")
);
const DataUsers = lazy(() =>
  import("../pages/ManageUsers/DataUsers/DataUsers.jsx")
);
const MasterBandrol = lazy(() =>
  import("../pages/DataMaster/DataBandrol/MasterBandrol.jsx")
);
const MasterHadiah = lazy(() =>
  import("../pages/DataMaster/DataHadiah/MasterHadiah.jsx")
);
const MasterJenis = lazy(() =>
  import("../pages/DataMaster/DataJenis/MasterJenis.jsx")
);
const MasterKodeTransaksi = lazy(() =>
  import("../pages/DataMaster/DataKodeTransaksi/MasterKodeTransaksi.jsx")
);
const MasterKondisiBarang = lazy(() =>
  import("../pages/DataMaster/DataKondisiBarang/MasterKondisiBarang.jsx")
);
const MasterTukang = lazy(() =>
  import("../pages/DataMaster/DataTukang/MasterTukang.jsx")
);
const MasterKondisiPembelian = lazy(() =>
  import("../pages/DataMaster/KondisiPembelian/MasterKondisiPembelian.jsx")
);
const MasterKondisiPinjaman = lazy(() =>
  import("../pages/DataMaster/KondisiPinjaman/MasterKondisiPinjaman.jsx")
);
const MasterBaki = lazy(() =>
  import("../pages/DataMaster/DataBaki/MasterBaki.jsx")
);
const MasterGudang = lazy(() =>
  import("../pages/DataMaster/DataGudang/MasterGudang.jsx")
);
const MasterSales = lazy(() =>
  import("../pages/DataMaster/DataSales/MasterSales.jsx")
);
const Dashboard = lazy(() => import("./../pages/Dashboard/dashboard.jsx"));
const MasterKategori = lazy(() =>
  import("../pages/DataMaster/DataKategori/MasterKategori.jsx")
);
const MasterParameterRugiNota = lazy(() =>
  import("../pages/DataMaster/ParameterRugiNota/MasterParameterRugiNota.jsx")
);
const SortirPembelian = lazy(() => import("../pages/SortirPembelian/index"));
//End Data Master

//Login
const Login = lazy(() => import("./../pages/auth/login.jsx"));

const routes = [
  //Login
  {
    path: "/import-data",
    exact: true,
    title: "Import Data",
    component: () => <ImportData />
  },
  {
    path: "/delete-data",
    exact: true,
    title: "Delete Data",
    component: () => <DelteDataTrancate />
  },
  {
    path: "/",
    exact: true,
    title: "Login",
    component: () => <Login />
  },
  {
    path: "/sortir-pembelian",
    exact: true,
    title: "Sortir Pembelian",
    component: () => <SortirPembelian />
  },
  {
    path: "/jenis-batu",
    exact: true,
    title: "Jenis Batu",
    component: () => <JenisBatu />
  },
  {
    path: "/master-bank",
    exact: true,
    title: "Master Bank",
    component: () => <MasterBank />
  },
  {
    path: "/master-rekening",
    exact: true,
    title: "Master Rekening",
    component: () => <MasterRekening />
  },
  {
    path: "/laporan-penjualan-margin",
    exact: true,
    title: "Laporan Penjualan Margin",
    component: () => <LaporanPenjualanMargin />
  },
  {
    path: "/laporan-buku-berlian",
    exact: true,
    title: "Laporan Buku Berlian",
    component: () => <BukiBerlian />
  },
  {
    path: "/laporan-terima-barang-supplier",
    exact: true,
    title: "Laporan Terima Barang Supplier",
    component: () => <LaporanTerimaBarangSupplier />
  },
  {
    path: "/penerimaan-barang",
    exact: true,
    title: "Penerimaan Barang",
    component: () => <PenerimaanBaarang />
  },

  //Dashboard
  {
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    component: () => <Dashboard />
  },

  // Data Master
  {
    path: "/master-kategori",
    exact: true,
    title: "Master Kategori",
    component: () => <MasterKategori />
  },

  // Data Jenis
  {
    path: "/master-jenis",
    exact: true,
    title: "Master Jenis",
    component: () => <MasterJenis />
  },

  // Data Group
  {
    path: "/master-group",
    exact: true,
    title: "Master Group",
    component: () => <MasterGroup />
  },

  // Master Bandrol
  {
    path: "/master-bandrol",
    exact: true,
    title: "Master Bandrol",
    component: () => <MasterBandrol />
  },

  // Master Bandrol
  {
    path: "/master-kode-transaksi",
    exact: true,
    title: "Master Kode Transaksi",
    component: () => <MasterKodeTransaksi />
  },

  //Master Gudang
  {
    path: "/master-gudang",
    exact: true,
    title: "Master Gudang",
    component: () => <MasterGudang />
  },

  //Master Baki
  {
    path: "/master-baki",
    exact: true,
    title: "Master Baki",
    component: () => <MasterBaki />
  },
  //Master Sales
  {
    path: "/master-sales",
    exact: true,
    title: "Master Sales",
    component: () => <MasterSales />
  },

  //Master Tukang
  {
    path: "/master-tukang",
    exact: true,
    title: "Master Tukang",
    component: () => <MasterTukang />
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-barang",
    exact: true,
    title: "Master Kondisi Barang",
    component: () => <MasterKondisiBarang />
  },
  {
    path: "/tambah-data-barang",
    exact: true,
    title: "Tambah Data Barang",
    component: () => <TambahBarang />
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-pembelian",
    exact: true,
    title: "Master Kondisi Pembelian",
    component: () => <MasterKondisiPembelian />
  },

  //Master Kondisi Barang
  {
    path: "/master-kondisi-pinjaman",
    exact: true,
    title: "Master Kondisi Pinjaman",
    component: () => <MasterKondisiPinjaman />
  },

  //Master Data Hadiah
  {
    path: "/master-data-hadiah",
    exact: true,
    title: "Master Data Hadiah",
    component: () => <MasterHadiah />
  },

  //Master Data Hadiah
  {
    path: "/master-parameter-rugi-nota",
    exact: true,
    title: "Master Parameter Rugi Nota",
    component: () => <MasterParameterRugiNota />
  },
  {
    path: "/master-parameter-harga-emas",
    exact: true,
    title: "Master Parameter Rugi Nota",
    component: () => <FormhargaEmas />
  },

  {
    path: "/master-kadar",
    exact: true,
    title: "Master Kadar",
    component: () => <MasterKadar />
  },

  // Master Belian
  {
    path: "/master-batu-shape",
    exact: true,
    title: "Batu Shape",
    component: () => <BatuShape />
  },
  {
    path: "/master-batu-color",
    exact: true,
    title: "Batu Color",
    component: () => <BatuColor />
  },
  {
    path: "/master-batu-clarity",
    exact: true,
    title: "Batu Clarity",
    component: () => <BatuClarity />
  },
  {
    path: "/master-batu-fluorencence",
    exact: true,
    title: "Batu Fluorencence",
    component: () => <BatuFluorencence />
  },
  {
    path: "/master-batu-symetry",
    exact: true,
    title: "Batu Fluorencence",
    component: () => <BatuSymetry />
  },
  {
    path: "/master-batu-polish",
    exact: true,
    title: "Batu Polish",
    component: () => <BatuPolish />
  },
  {
    path: "/master-batu-cutgrade",
    exact: true,
    title: "Batu Cutgrade",
    component: () => <BatuCutgrade />
  },
  {
    path: "/master-batu-berlian",
    exact: true,
    title: "Batu Berlian",
    component: () => <BatuBerlian />
  },

  //End Data Master

  //Transaksi pembelian
  {
    path: "/transaksi-pembelian",
    exact: true,
    title: "Transaksi Pembelian",
    component: () => <FormTransaksiPembelian />
  },
  {
    path: "/lihat-pembelian",
    exact: true,
    title: "Lihat Pembelian",
    component: () => <FormLihatPembelian />
  },
  {
    path: "/batal-pembelian",
    exact: true,
    title: "Batal Pembelian",
    component: () => <FormBatalPembelian />
  },
  {
    path: "/laporan-pembelian-sales",
    exact: true,
    title: "Laporan Pembelian Sales",
    component: () => <FormLaporanPembelianSales />
  },

  {
    path: "/laporan-transaksi-pembelian",
    exact: true,
    title: "Laporan Transaksi Pembelian",
    component: () => <FormLaporanPembelianBarang />
  },
  {
    path: "/laporan-transaksi-pembelian-history",
    exact: true,
    title: "Laporan Transaksi Pembelian History",
    component: () => <FormLaporanPembelianBarangHistory />
  },
  {
    path: "/laporan-batal-pembelian",
    exact: true,
    title: "Laporan Batal Pembelian",
    component: () => <FormLaporanBatalBeli />
  },
  {
    path: "/master-parameter-point",
    exact: true,
    title: "Parameter Point",
    component: () => <FormParamterPoint />
  },

  //Transaksi Penjualan
  {
    path: "/transaksi-penjualan",
    exact: true,
    title: "Transaksi Penjualan",
    component: () => <FormTransaksiPenjualan />
  },
  {
    path: "/lihat-penjualan",
    exact: true,
    title: "Lihat Penjualan",
    component: () => <FormLihatPenjualan />
  },
  {
    path: "/validasi-penjualan",
    exact: true,
    title: "Validasi Penjualan",
    component: () => <FormValidasiPenjualan />
  },
  {
    path: "/validasi-titipan",
    exact: true,
    title: "Validasi titipan",
    component: () => <FormValidasiTitipan />
  },
  {
    path: "/batal-penjualan",
    exact: true,
    title: "Batal Penjualan",
    component: () => <FormBatalPenjualan />
  },
  {
    path: "/lihat-titipan",
    exact: true,
    title: "Lihat Titipan",
    component: () => <BatalTitipan />
  },
  {
    path: "/laporan-batal-penjualan",
    exact: true,
    title: "Laporan Batal Penjualan",
    component: () => <FormLaporanBatalPenjualan />
  },
  {
    path: "/transaksi-pesanan",
    exact: true,
    title: "Transaksi Pesanan",
    component: () => <Pesanan />
  },
  {
    path: "/lihat-pesanan",
    exact: true,
    title: "Lihat Pesanan",
    component: () => <LihatPesanan />
  },
  {
    path: "/laporan-titip-uang",
    exact: true,
    title: "Laporan Titip Uang",
    component: () => <LaporanTitipUang />
  },
  {
    path: "/laporan-pesanan",
    exact: true,
    title: "Laporan Pesanan",
    component: () => <LaporanPesanan />
  },
  {
    path: "/tambah-uang-bank",
    exact: true,
    title: "Tambah Uang Bank",
    component: () => <TambahUangBank />
  },
  {
    path: "/ambil-uang-bank",
    exact: true,
    title: "Ambil Uang Bank",
    component: () => <AmbilUangBank />
  },

  // {
  //   path: "/laporan-penjualan-sales",
  //   exact: true,
  //   title: "Laporan Penjualan Sales",
  //   component: () => <FormLaporanPenjualanSales />,
  // },
  {
    path: "/laporan-penjualan-sales",
    exact: true,
    title: "Laporan Penjualan Sales",
    component: () => <FormLaporanPenjualanSales />
  },
  {
    path: "/laporan-penjualan-rekap",
    exact: true,
    title: "Laporan Penjualan Rekap",
    component: () => <LaporanPenjualanTaxPurpose />
  },
  {
    path: "/laporan-transaksi-penjualan",
    exact: true,
    title: "Laporan Transaksi Penjualan",
    component: () => <FormLaporanPenjualanBarang />
  },
  {
    path: "/laporan-penjualan-bayar",
    exact: true,
    title: "Laporan Penjualan Bayar",
    component: () => <FormLaporanPenjualanBayar />
  },

  //Manage Data
  {
    path: "/import-data-pusat",
    exact: true,
    title: "Import Data Pusat",
    component: () => <ImportDataPusat />
  },
  {
    path: "/kirim-barang-kecabang",
    exact: true,
    title: "Kirim Barang Kecabang",
    component: () => <KirimDataBarang />
  },

  //Services
  {
    path: "/transaksi-services",
    exact: true,
    title: "Transaksi Services",
    component: () => <FormTransaksiServces />
  },
  {
    path: "/lihat-services",
    exact: true,
    title: "Lihat Services",
    component: () => <LihatServices />
  },
  {
    path: "/laporan-services",
    exact: true,
    title: "Laporan Services",
    component: () => <FormLaporanService />
  },
  {
    path: "/laporan-stock-hutang",
    exact: true,
    title: "Laporan Stock Hutang",
    component: () => <LaporanStockHutang />
  },
  {
    path: "/laporan-batal-service",
    exact: true,
    title: "Laporan Batal Services",
    component: () => <FormLaporanBatalService />
  },
  {
    path: "/laporan-service-ambil",
    exact: true,
    title: "Laporan Serice Ambil",
    component: () => <FormLaporanServiceAmbil />
  },

  //Manage Users
  {
    path: "/data-users",
    exact: true,
    title: "Data Users",
    component: () => <DataUsers />
  },
  {
    path: "/hak-akses-users",
    exact: true,
    title: "Hak Akses Users",
    component: () => <FormHakAkses />
  },

  //Barang
  {
    path: "/data-barang",
    exact: true,
    title: "Data Barang",
    component: () => <DataBarang />
  },
  // {
  //   path: "/tambah-data-barang",
  //   exact: true,
  //   title: "Tambah Data Barang",
  //   component: () => <FormTambahBarang />,
  // },

  //Terima Barang
  {
    path: "/terima-barang",
    exact: true,
    title: "Terima Barang",
    component: () => <TerimaBarang />
  },
  //Terima Barang
  {
    path: "/pindah-barang",
    exact: true,
    title: "Pindah Barang",
    component: () => <FormPindahBarang />
  },

  //Hancur Barang
  {
    path: "/hancur-data-barang",
    exact: true,
    title: "Hancur Data Barang",
    component: () => <FormHancurBarang />
  },

  //Pindah Barang Satu Baki
  {
    path: "/pindah-barang-satu-baki",
    exact: true,
    title: "Pindah Barang Satu Baki",
    component: () => <FormPindahBarangSatuBaki />
  },

  //Pindah Barang Satu Baki
  {
    path: "/timbang-baki",
    exact: true,
    title: "Timbang barang",
    component: () => <TimbangBarang />
  },

  //Transaksi Hutang
  {
    path: "/transaksi-hutang",
    exact: true,
    title: "Transaksi Hutang",
    component: () => <FormTransaksiHutang />
  },
  {
    path: "/lihat-hutang",
    exact: true,
    title: "Lihat Hutang",
    component: () => <FormLihatHutang />
  },
  {
    path: "/validasi-hutang",
    exact: true,
    title: "Validasi Hutang",
    component: () => <FormValidasiHutang />
  },
  {
    path: "/validasi-jual-beli",
    exact: true,
    title: "Validasi Jual Beli",
    component: () => <FormValidasiJualBeli />
  },

  //Uang Kas
  // {
  //   path: "/tambah-uang-kas",
  //   exact: true,
  //   title: "Tambah Uang Kas",
  //   component: () => <TambahUangKas />,
  // },
  // {
  //   path: "/ambil-uang-kas",
  //   exact: true,
  //   title: "Ambil Uang Kas",
  //   component: () => <AmbilUangKas />,
  // },

  // Laporan

  // Laporan Keuangan
  {
    path: "/laporan-transaksi-keuangan-cash",
    exact: true,
    title: "Laporan Transaksi Keuangan Cash",
    component: () => <LaporanKeuanganKas />
  },
  {
    path: "/laporan-keuangan-non-cash",
    exact: true,
    title: "Laporan Keuangan Non Cash",
    component: () => <LaporanNonCash />
  },

  //Laporan Terima Barang
  {
    path: "/laporan-terima-barang",
    exact: true,
    title: "Laporan Terima Barang",
    component: () => <FormLaporanTerimaBarang />
  },
  {
    path: "/laporan-otorisasi",
    exact: true,
    title: "Laporan Otorisasi",
    component: () => <LaporanOtorisasi />
  },
  //Laporan Pindah Barang
  {
    path: "/laporan-pindah-barang",
    exact: true,
    title: "Laporan Pindah Barang",
    component: () => <FormLaporanPindahBarang />
  },
  //Laporan Hancur Barang
  {
    path: "/laporan-hancur-barang",
    exact: true,
    title: "Laporan Hancur Barang",
    component: () => <FormLaporanHancurBarang />
  },

  //Laporan Detail Barang
  {
    path: "/laporan-barang-detail",
    exact: true,
    title: "Laporan Barang Detail",
    component: () => <FormDetailBarang />
  },
  {
    path: "/laporan-barang-sumary",
    exact: true,
    title: "Laporan Barang Sumary",
    component: () => <FormLaporanBarangSumary />
  },
  {
    path: "/laporan-penjualan-marketplace",
    exact: true,
    title: "Laporan Penjualan Marketplace",
    component: () => <LaporanPenjualanMarketPlace />
  },
  {
    path: "/laporan-stock-jual",
    exact: true,
    title: "Laporan Stock Jual",
    component: () => <LaporanStockJual />
  },
  {
    path: "/laporan-service-masuk",
    exact: true,
    title: "Laporan Service Masuk",
    component: () => <LaporanServiceMasuk />
  },
  {
    path: "/laporan-service-selesai",
    exact: true,
    title: "Laporan Service Selesai",
    component: () => <LaporanServiceSelesai />
  },

  {
    path: "/laporan-tambah-barang",
    exact: true,
    title: "Laporan Tambah Barang",
    component: () => <FormLaporanTambahBarang />
  },

  {
    path: "/settingNota",
    exact: true,
    title: "Setting Nota",
    component: () => <SettingNota />
  },

  {
    path: "/settingsystem",
    exact: true,
    title: "Setting System",
    component: () => <SettingSystem />
  },
  {
    path: "/cetak-barcode",
    exact: true,
    title: "Cetak Barcode",
    component: () => <CetakBarcode />
  },

  {
    path: "/data-member",
    exact: true,
    title: "Data Member",
    component: () => <DataMember />
  },
  {
    path: "/lihat-transaksi-member",
    exact: true,
    title: "Lihat Transaksi Member",
    component: () => <LihatTransaksiMember />
  },
  {
    path: "/transaksi-poin",
    exact: true,
    title: "Transaksi Poin",
    component: () => <FormTransaksiPoin />
  },
  {
    path: "/tukar-point",
    exact: true,
    title: "Tukar Poin",
    component: () => <FormTukarPoin />
  },
  {
    path: "/laporan-daftar-member",
    exact: true,
    title: "Laporan daftar Member",
    component: () => <FormLaporanMember />
  },
  {
    path: "/laporan-transaksi-hutang",
    exact: true,
    title: "Laporan Transaksi Hutang",
    component: () => <FormLaporanHutang />
  },
  {
    path: "/laporan-hutang-lunas",
    exact: true,
    title: "Laporan Hutang Lunas",
    component: () => <FormLaporanHutangLunas />
  },
  {
    path: "/laporan-batal-hutang",
    exact: true,
    title: "Laporan Batal Hutang",
    component: () => <FormLaporanBatalHutang />
  },
  {
    path: "/stock-opname",
    exact: true,
    title: "Stock Opname",
    component: () => <FormStockOpname />
  },

  {
    path: "/stock-opname-penerimaan",
    exact: true,
    title: "Stock Opname Penerimaan",
    component: () => <StockOpnamePenerimaan />
  },
  {
    path: "/uang-kas",
    exact: true,
    title: "Lihat Uang kas",
    component: () => <LihatUangKas />
  },
  {
    path: "/laporan-eod",
    exact: true,
    title: "Laporan Eod",
    component: () => <FormLaporanEod />
  },
  {
    path: "/laporan-authorization",
    exact: true,
    title: "Laporan Eod",
    component: () => <LaporanOtorisasi />
  },

  {
    path: "/laporan-timbang-baki",
    exact: true,
    title: "Laporan Timbang Baki",
    component: () => <FormLaporanTimbangBarang />
  },

  {
    path: "/laporan-barang-tukar",
    exact: true,
    title: "Laporan Barang Tukar",
    component: () => <FormLaporanPenjualanTukar />
  },
  {
    path: "/cucian",
    exact: true,
    title: "Cucian",
    component: () => <Cucian />
  },
  {
    path: "/pindah-sortir",
    exact: true,
    title: "Pindah Sortir",
    component: () => <PindahSortir />
  },
  {
    path: "/settingtoko",
    exact: true,
    title: "Setting Toko",
    component: () => <SettingToko />
  },
  {
    path: "/hak-akses-news",
    exact: true,
    title: "Hak Akses News",
    component: () => <HakAksesNews />
  },
  {
    path: "/master-marketplace",
    exact: true,
    title: "Master Marketplace",
    component: () => <DataMareketPlace />
  },
  {
    path: "/blank",
    exact: true,
    title: "blank",
    component: () => <BlankPages />
  }
];

export default routes;
