import { FORM_MODAL } from "../actions/databarang_action";
import {
  GET_DATA_PROVINSI,
  GET_DATA_BATU_COLOR,
  GET_DATA_CLARITY,
  GET_DATA_SHAPE,
  SET_FOCUS,
  SHOW_DATA_MODAL,
  GET_DATA_BANK,
  HIDE_MODAL,
  SHOW_MODAL,
  EDIT_DATA_PROVINSI,
  SHOW_MODAL_KATEGORI,
  SHOW_MODAL_JENIS,
  SHOW_MODAL_KODE_TRANSAKSI,
  SHOW_MODAL_GUDANG,
  SHOW_MODAL_BAKI,
  SHOW_MODAL_SALES,
  SHOW_MODAL_TUKANG,
  SHOW_MODALKONDISI_BARANG,
  SHOW_MODOAL_KONDISI_PEMBELIAN,
  SHOW_MODAL_KONDISI_PINJAMAN,
  SHOW_MODAL_DATA_HADIAH,
  SHOW_MODAL_RUGI_NOTA,
  SHOW_MODAL_GROUP,
  GET_DATA_GROUP,
  GET_DATA_JENIS,
  SHOW_MODAL_KADAR,
  GET_DATA_KADAR,
  GET_DATA_GUDANG,
  GET_DATA_BAKI,
  GET_BANDROL,
  SHOW_MODAL_BANDROL,
  GET_DATA_SALES,
  GET_DATA_TUKANG,
  GET_KODE_TRANSAKSI,
  GET_DATA_KONDISI_BARANG,
  GET_KONDISI_PEMBELIAN,
  GET_KONDISI_PINJAMAN,
  GET_PARAMETER_POINT,
  SHOW_MODAL_PARAMETER_POINT,
  GET_DATA_HADIAH,
  SHOW_MODAL_PARAMETER_HARGA_EMAS,
  GET_PARAMETER_HARGA_EMAS,
  GET_PARAMETER_RUGI_NOTA,
  ISLOADING,
  ISEDIT,
  SHOW_MODAL_MARKETPLACE,
  GET_DATA_MARKETPLCAE,
  SET_NO,
  GET_DATA_KAS,
  GET_DATA_FLU,
  GET_DATA_SYEMETRY,
  GET_DATA_POLISH,
  GET_DATA_CUTGRAD,
  GET_BANK,
  GET_REKENING,
  GET_JENIS_BATU,
  GET_DATA_PENJUALAN_PERBULAN,
  GET_DATA_PEMBELIAN_PERBULAN,
  GET_PENJUALAN_TODAY,
  GET_PEMBELIAN_TODAY,
  GET_PESANAN_TODAY,
  GET_SERVICE_TODAY,
  GET_DATA_JENIS_BERLIAN,
  DATA_TMP,
} from "../actions/datamaster_action";

const initialState = {
  getDataBank: false,
  getDataProvinsi: false,
  modalDialog: false,
  editModalProvinsi: false,
  ShowtModalKategori: false,
  ShowModalJenis: false,
  ShowModalKodeTransaksi: false,
  ShowModalGudang: false,
  ShowModalBaki: false,
  ShowModalSales: false,
  ShowModalTukang: false,
  ShowModalKondisiBarang: false,
  ShowModalKondisiPembelian: false,
  ShowModalKondisiPinjaman: false,
  ShowModalDataHadiah: false,
  ShowModalParameterRugiNota: false,
  ShowModalGroup: false,
  GetDataGroup: [],
  getDataJenis: [],
  ShowModalDataKadar: false,
  getDataKadar: false,
  getDataGudang: [],
  getDataBaki: [],
  getBandrol: false,
  ShowModalBandrol: false,
  getDataSales: [],
  getDataTukang: false,
  getParamterTransaksi: false,
  getDataKondisiBarang: [],
  getKondisiPembelian: [],
  getKondisiPinjaman: [],
  getParameterPoint: [],
  ShowModalParameterPoint: [],
  ShowModalParameterHargaEmas: [],
  getParameterHargaEmas: [],
  getDataHadiah: [],
  getParameterRugiNota: [],
  isLoading: false,
  isEdit: false,
  setFocus: false,
  getDataMarketplace: [],
  showModalMarketPlace: [],
  getDataKas: [],
  setNo: "",
  ShowDataModal: false,
  getDataClarity: [],
  getDataShape: [],
  getBatuColor: [],
  getDataFluorencence: [],
  getDataSymetry: [],
  getDataPolish: [],
  getDataCutgrad: [],
  getDataBanks: [],
  getDataRekening: [],
  getJenisBatu: [],
  FormModal: '',
  getDataPenjualanPerbulan: [],
  getDataPembelianPerbulan: [],
  getJenisBerlian: [],
  getPembelianToday: 0,
  getPesananToday: 0,
  getServicesToday: 0,
  getDataTmp: [],
};

const datamaster = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_DATA_JENIS_BERLIAN:
      return {
        ...state,
        getJenisBerlian: actions.payload.data,
      };
    case GET_SERVICE_TODAY:
      return {
        ...state,
        getServicesToday: actions.payload.data,
      };
    case GET_PESANAN_TODAY:
      return {
        ...state,
        getPesananToday: actions.payload.data,
      };
    case GET_PEMBELIAN_TODAY:
      return {
        ...state,
        getPembelianToday: actions.payload.data,
      };
    case GET_PENJUALAN_TODAY:
      return {
        ...state,
        getPenjualanToday: actions.payload.data,
      };
    case GET_DATA_PEMBELIAN_PERBULAN:
      return {
        ...state,
        getDataPembelianPerbulan: actions.payload.data,
      };
    case GET_DATA_PENJUALAN_PERBULAN:
      return {
        ...state,
        getDataPenjualanPerbulan: actions.payload.data,
      };
    case GET_JENIS_BATU:
      return {
        ...state,
        getJenisBatu: actions.payload.data,
      };
    case FORM_MODAL:
      return {
        ...state,
        FormModal: actions.payload.data,
      };
    case GET_REKENING:
      return {
        ...state,
        getDataRekening: actions.payload.data,
      };
    case GET_BANK:
      return {
        ...state,
        getDataBanks: actions.payload.data,
      };
    case GET_DATA_CUTGRAD:
      return {
        ...state,
        getDataCutgrad: actions.payload.data,
      };
    case GET_DATA_POLISH:
      return {
        ...state,
        getDataPolish: actions.payload.data,
      };
    case GET_DATA_SYEMETRY:
      return {
        ...state,
        getDataSymetry: actions.payload.data,
      };
    case GET_DATA_FLU:
      return {
        ...state,
        getDataFluorencence: actions.payload.data,
      };
    case GET_DATA_BATU_COLOR:
      return {
        ...state,
        getBatuColor: actions.payload.data,
      };
    case GET_DATA_SHAPE:
      return {
        ...state,
        getDataShape: actions.payload.data,
      };
    case GET_DATA_CLARITY:
      return {
        ...state,
        getDataClarity: actions.payload.data,
      };
    case GET_DATA_KAS:
      return {
        ...state,
        getDataKas: actions.payload.data,
      };
    case SHOW_DATA_MODAL:
      return {
        ...state,
        ShowDataModal: actions.payload.data,
      };
    case SET_NO:
      return {
        ...state,
        setNo: actions.payload.data,
      };
    case SET_FOCUS:
      return {
        ...state,
        setFocus: actions.payload.data,
      };
    case SHOW_MODAL_MARKETPLACE:
      return {
        ...state,
        showModalMarketPlace: actions.payload.data,
      };
    case GET_DATA_MARKETPLCAE:
      return {
        ...state,
        getDataMarketplace: actions.payload.data,
      };
    case GET_DATA_BANK:
      return {
        ...state,
        getDataBank: actions.payload.data,
      };
    case ISLOADING:
      return {
        ...state,
        isLoading: actions.payload.data,
      };
    case ISEDIT:
      return {
        ...state,
        isEdit: actions.payload.data,
      };
    case GET_PARAMETER_RUGI_NOTA:
      return {
        ...state,
        getParameterRugiNota: actions.payload.data,
      };
    case GET_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        getParameterHargaEmas: actions.payload.data,
      };
    case SHOW_MODAL_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        ShowModalParameterHargaEmas: actions.payload.data,
      };
    case GET_DATA_PROVINSI:
      return {
        ...state,
        getDataProvinsi: actions.payload.data,
      };
    case GET_PARAMETER_POINT:
      return {
        ...state,
        getParameterPoint: actions.payload.data,
      };
    case GET_DATA_HADIAH:
      return {
        ...state,
        getDataHadiah: actions.payload.data,
      };
    case SHOW_MODAL_PARAMETER_POINT:
      return {
        ...state,
        ShowModalParameterPoint: actions.payload.data,
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalDialog: actions.payload.data,
      };
    case SHOW_MODAL:
      return {
        ...state,
        modalDialog: actions.payload.data,
      };
    case EDIT_DATA_PROVINSI:
      return {
        ...state,
        editModalProvinsi: actions.payload.data,
      };
    case SHOW_MODAL_KATEGORI:
      return {
        ...state,
        ShowtModalKategori: actions.payload.data,
      };

    //Data Jenis
    case SHOW_MODAL_JENIS:
      return {
        ...state,
        ShowModalJenis: actions.payload.data,
      };
    case GET_DATA_JENIS:
      return {
        ...state,
        getDataJenis: actions.payload.data,
      };
    //End Jenis

    //Data Kadar
    case SHOW_MODAL_KADAR:
      return {
        ...state,
        ShowModalDataKadar: actions.payload.data,
      };
    case GET_DATA_KADAR:
      return {
        ...state,
        getDataKadar: actions.payload.data,
      };

    //Data Kode Transaksi
    case GET_KODE_TRANSAKSI:
      return {
        ...state,
        getParamterTransaksi: actions.payload.data,
      };
    case SHOW_MODAL_KODE_TRANSAKSI:
      return {
        ...state,
        ShowModalKodeTransaksi: actions.payload.data,
      };

    //Data Gudang
    case SHOW_MODAL_GUDANG:
      return {
        ...state,
        ShowModalGudang: actions.payload.data,
      };
    case GET_DATA_GUDANG:
      return {
        ...state,
        getDataGudang: actions.payload.data,
      };
    //End Data Gudang

    //Data Baki
    case SHOW_MODAL_BAKI:
      return {
        ...state,
        ShowModalBaki: actions.payload.data,
      };
    case GET_DATA_BAKI:
      return {
        ...state,
        getDataBaki: actions.payload.data,
      };

    //ENd Data Baki

    case GET_BANDROL:
      return {
        ...state,
        getBandrol: actions.payload.data,
      };
    case SHOW_MODAL_BANDROL:
      return {
        ...state,
        ShowModalBandrol: actions.payload.data,
      };

    //Data Sales
    case SHOW_MODAL_SALES:
      return {
        ...state,
        ShowModalSales: actions.payload.data,
      };
    case GET_DATA_SALES:
      return {
        ...state,
        getDataSales: actions.payload.data,
      };

    //End Data Sales

    //Function Data Sales
    case SHOW_MODAL_TUKANG:
      return {
        ...state,
        ShowModalTukang: actions.payload.data,
      };
    case GET_DATA_TUKANG:
      return {
        ...state,
        getDataTukang: actions.payload.data,
      };

    //Function Kondisi Barang
    case SHOW_MODALKONDISI_BARANG:
      return {
        ...state,
        ShowModalKondisiBarang: actions.payload.data,
      };
    case GET_DATA_KONDISI_BARANG:
      return {
        ...state,
        getDataKondisiBarang: actions.payload.data,
      };

    //Function Kondisi Pembelian
    case GET_KONDISI_PEMBELIAN:
      return {
        ...state,
        getKondisiPembelian: actions.payload.data,
      };
    case SHOW_MODOAL_KONDISI_PEMBELIAN:
      return {
        ...state,
        ShowModalKondisiPembelian: actions.payload.data,
      };

    //FUnction Kondisi Pinjaman
    case GET_KONDISI_PINJAMAN:
      return {
        ...state,
        getKondisiPinjaman: actions.payload.data,
      };
    case SHOW_MODAL_KONDISI_PINJAMAN:
      return {
        ...state,
        ShowModalKondisiPinjaman: actions.payload.data,
      };

    case SHOW_MODAL_DATA_HADIAH:
      return {
        ...state,
        ShowModalDataHadiah: actions.payload.data,
      };
    case SHOW_MODAL_RUGI_NOTA:
      return {
        ...state,
        ShowModalParameterRugiNota: actions.payload.data,
      };
    case SHOW_MODAL_GROUP:
      return {
        ...state,
        ShowModalGroup: actions.payload.data,
      };
    case GET_DATA_GROUP:
      return {
        ...state,
        GetDataGroup: actions.payload.data,
      };
    case DATA_TMP:
      return {
        ...state,
        getDataTmp: actions.payload.data,
      };
    default:
      return state;
  }
};

export default datamaster;
