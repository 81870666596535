const datanota = (state) => state.utility.getDataNota;
const systemId = (state) => state.utility.getParaSystemId;
const isEdit = (state) => state.datamaster.isEdit;

const selectorUtility = {
  datanota,
  isEdit,
  systemId
};


export {
    selectorUtility
}