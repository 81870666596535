import { convertDate } from "../../../../../components/helpers/function";
import { getDataParams, isLoading, simpanDataTmp, ToastNotification } from "../../../../../components/helpers/library";
// import LaporanPenjualanTaxPurpose from "../Laporan";

export const cetakLaporanPdf = (data) => {
    return async (dispatch, getState) => {
        let filter = {
            tgl_from: convertDate(data.tgl_awal),
            tgl_to: convertDate(data.tgl_akhir)
        };
        dispatch(isLoading(true));
        try {
            let result = await getDataParams(
                "penjualan/report-rekap-jual-pajak",
                filter
            );
            if (result.data.length === 0) {
                ToastNotification('info', 'Laporan Tidak Tersedia');
                dispatch(isLoading(false));
                return false
            }
            if (result.data) {
                ToastNotification("success", "Laporan Tersedia");

                dispatch(isLoading(false));
                dispatch(simpanDataTmp({
                    data: result.data,
                    filter
                }))
            }
        } catch (error) {
            console.log(error)
            dispatch(isLoading(false));
            ToastNotification(
                "info",
                error?.response?.data || "Terjadi kesalahan saat mengirim data"
            );
        }
    };
};
