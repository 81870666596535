import React, { useEffect } from "react";
import FilterLaporanTgl from "../../../../../components/helpers/FilterLaporanTgl";
import { change, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";

let Form = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(change("FormPenjualanTaxPurpose", "tgl_awal", new Date()));
        dispatch(change("FormPenjualanTaxPurpose", "tgl_akhir", new Date()));
    }, [dispatch]);
    return (
        <form onSubmit={props.handleSubmit} autoComplete="off">
            <FilterLaporanTgl />
        </form>
    );
};

Form = reduxForm({
    form: "FormPenjualanTaxPurpose",
    enableReinitialize: true
})(Form);
export default Form;
