
import { getJenisBatu,ShowDataModal } from "../../../../actions/datamaster_action";

import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ModalGlobal,
  Tabel,
  showModal,
  hideModal,
  ToastNotification,
  postDataNoTImeOut,
  Swal,
  reset,
  deleteData,
  putDataNoTImeOut,
} from "../../../../components/helpers/library";
import HeadJenisBatu from "./HeadJenisBatu";
const maptostate = (state)=>{
  return {
    data : state.datamaster.getJenisBatu
  }
}
class JenisBatu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "jenis_batu",
          text: "Jenis Batu",
        },
        {
          dataField: "nama_batu",
          text: "Nama Batu",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            let dataEdit = {
              id: row._id,
              jenis_batu: row.jenis_batu,
              nama_batu: row.nama_batu,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => this.editData(dataEdit)}
                    className="btn btn-primary mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    onClick={() => this.hapusData(row)}
                    className="btn btn-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    this.state.isEdit ?
    putDataNoTImeOut('parameter-berlian/'+data.id,data).then((res)=>{
      ToastNotification("success",res?.data?.message || "Berhasil Menyimpan Data")
      this.setState({
        isLoading: false,
      });
      this.props.dispatch(hideModal());
      this.props.dispatch(getJenisBatu())
    }).catch((err)=>{
      this.setState({
        isLoading: false,
      });
      ToastNotification("info",err.response?.data?.message || "Terjadi Kesalhaan Saat Mengirim Data")
    })
    :
    postDataNoTImeOut('parameter-berlian',data).then((res)=>{
      ToastNotification("success",res?.data?.message || "Berhasil Menyimpan Data")
      this.setState({
        isLoading: false,
      });
      this.props.dispatch(hideModal());
      this.props.dispatch(getJenisBatu())
      this.props.dispatch(reset());
    }).catch((err)=>{
      this.setState({
        isLoading: false,
      });
      ToastNotification("info",err.response?.data?.message || "Terjadi Kesalhaan Saat Mengirim Data")
    })
  }

  componentDidMount() {
    this.props.dispatch(getJenisBatu())
  }

  editData(data) {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowDataModal(data));
    this.setState({
      isEdit: true,
    });
  }
  showModal() {
    this.props.dispatch(showModal());
    this.props.dispatch(ShowDataModal(true));
    this.setState({
      isEdit: false,
    });
  }

  hapusData(row) {
    Swal.fire({
      // title: "Anda Yakin !!",
      // text: "Ingin Menghapus Data Ini ?",
      html:
        "Apakah Anda Yakin Ingin " +
        "Menghapus " +
        "<h1><b>Jenis Batu " +
        row.jenis_batu +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData('parameter-berlian/'+row._id).then((res)=>{
          ToastNotification('success',res.data.message || "Berhasil Menghapus Data")
          this.setState({
            isLoading: false,
          });
          this.props.dispatch(getJenisBatu())
        }).catch((err)=>{
          ToastNotification('info',err.response?.data?.message || "Gagal Menghapus Data")
          this.setState({
            isLoading: false,
          });
        })
      }
    });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Data Berlian</Link>
          </li>
          <li className="breadcrumb-item active">Jenis Batu</li>
        </ol>
        <h1 className="page-header">Jenis Batu </h1>
        <Panel>
          <PanelHeader>Jenis Batu</PanelHeader>
          <PanelBody>
            <Tabel
              keyField="kode_batu"
              tambahData={false}
              handleClick={() => this.showModal()}
              exportCsv={true}
              data={this.props.data}
              columns={this.state.columns}
              empty={this.props.data}
              textEmpty="Jenis Batu Kosong"
            />
          </PanelBody>
        </Panel>

        <ModalGlobal
          size="P"
          title={this.state.isEdit ? "Edit Data Batu" : "Tambah Data Batu"}
          content={
            <HeadJenisBatu
              isLoading={this.state.isLoading}
              isEdit={this.state.isEdit}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          }
        />
      </div>
    );
  }
}

JenisBatu = reduxForm({
  form: "JenisBatu",
  enableReinitialize: true,
})(JenisBatu);
export default connect(maptostate)(JenisBatu);
