import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ReanderSelect,
  Field
} from "../components/helpers/library";

import SortableTree, {
  toggleExpandedForAll,
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
// import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { getDataUser } from "../actions/mageuser_action";

const maptostate = (state) => {
  return {
    DataUsers: state.manageusers.getDataUser,
  }
}
class BlankPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
      searchString: "",
      searchFocusIndex: 0,
      treeData: [
        {
          path: "/dashboard",
          icon: "fa fa-th",
          title: "Dashboard",
          hidden: false,
          children: []
        },

        {
          path: "#",
          icon: "fa fa-hdd",
          title: "Data Master",
          hidden: false,
          children: [
            { path: "/master-group", title: "Master Group", hidden: false },
            // { path: 'master-kategori', title: 'Master Kategori' },
            { path: "/master-jenis", title: "Master Jenis", hidden: false },
            { path: "/master-gudang", title: "Master Gudang", hidden: false },
            { path: "/master-baki", title: "Master Baki", hidden: false },
            { path: "/master-bandrol", title: "Master Bandrol", hidden: false },
            // { path: '#', title: 'Master Pelanggan Non Member' },
            { path: "/master-sales", title: "Master Sales", hidden: false },
            {
              path: "/master-marketplace",
              title: "Master Marketplace",
              hidden: false,
            },
            // { path: '/master-tukang', title: 'Master Tukang' },
            {
              path: "/master-kode-transaksi",
              title: "Master Kode Transaksi",
              hidden: false,
            },
            {
              path: "/master-kondisi-barang",
              title: "Master Kondisi Barang",
              hidden: false,
            },
            {
              path: "/master-kondisi-pembelian",
              title: "Master Kondisi Pembelian",
              hidden: false,
            },
            // { path: "/master-kondisi-pinjaman", title: "Master Kondisi Pinjaman" },
            // { path: '/master-kadar', title: 'Master Kadar' },
            // { path: "/master-data-hadiah", title: "Master Hadiah" },
            // { path: "/master-parameter-point", title: "Parameter Point" },
            {
              path: "/master-parameter-harga-emas",
              title: "Parameter Harga Emas",
              hidden: false,
            },
            // { path: '/master-parameter-rugi-nota', title: 'Parameter Rugi Nota' },
          ],
        },

        // {
        //   path: '#', icon: 'fa fa-database', title: 'Manage Data',
        //   children: [
        //     { path: '/import-data-pusat', title: 'Import Data Pusat' },
        //     { path: '/kirim-barang-kecabang', title: 'Kirim Barang Ke Cabang' },
        //   ],
        // },

        {
          path: "#",
          icon: "fa fa-archive",
          title: "Barang",
          hidden: false,
          children: [
            {
              path: "/tambah-data-barang",
              title: "Tambah Data Barang",
              hidden: false,
            },
            { path: "/data-barang", title: "Data Barang", hidden: false },
            // { path: "/terima-barang", title: "Terima Barang" },
            {
              path: "/hancur-data-barang",
              title: "Hancur Data Barang",
              hidden: false,
            },
            { path: "/pindah-barang", title: "Pindah Barang", hidden: false },
            {
              path: "/pindah-barang-satu-baki",
              title: "Pindah Barang Satu Baki",
              hidden: false,
            },
            { path: "/timbang-baki", title: "Timbang Baki", hidden: false },
          ],
        },
        {
          path: "#",
          icon: "fa fa-shopping-cart",
          title: "Penjualan",
          hidden: false,
          children: [
            {
              path: "/transaksi-penjualan",
              title: "Transaksi Penjualan",
              hidden: false,
            },
            {
              path: "/lihat-penjualan",
              title: "Lihat Penjualan",
              hidden: false,
            },
            {
              path: "/batal-penjualan",
              title: "Batal Penjualan",
              hidden: false,
            },
            { path: "/lihat-titipan", title: "Lihat Titipan", hidden: false },
          ],
        },
        {
          path: "#",
          icon: "fa fa-boxes",
          title: "Pesanan",
          hidden: false,
          children: [
            {
              path: "/transaksi-pesanan",
              title: "Transaksi Pesanan",
              hidden: false,
            },
            { path: "/lihat-pesanan", title: "Lihat Pesanan", hidden: false },
          ],
        },

        {
          path: "#",
          icon: "fa fa-shopping-bag",
          title: "Pembelian",
          hidden: false,
          children: [
            {
              path: "/transaksi-pembelian",
              title: "Transaksi Pembelian",
              hidden: false,
            },
            {
              path: "/lihat-pembelian",
              title: "Lihat Pembelian",
              hidden: false,
            },
          ],
        },
        {
          path: "#",
          icon: 'ion ion-md-build fa-2x fa-fw pull-left m-r-10 text-black-lighter"',
          title: "Service",
          hidden: false,
          children: [
            {
              path: "/transaksi-services",
              title: "Transaksi Service",
              hidden: false,
            },
            { path: "/lihat-services", title: "Lihat Services", hidden: false },
          ],
        },

        {
          path: "#",
          icon: "fa fa-hand-holding-usd",
          title: "Hutang",
          hidden: false,
          children: [
            {
              path: "/transaksi-hutang",
              title: "Transaksi Hutang",
              hidden: false,
            },
            { path: "/lihat-hutang", title: "Lihat Hutang", hidden: false },
          ],
        },

        {
          path: "#",
          icon: "fas fa-money-bill-alt",
          title: "Uang Kas",
          hidden: false,
          children: [
            {
              path: "/tambah-uang-kas",
              title: "Tambah Uang Kas",
              hidden: false,
            },
            { path: "/ambil-uang-kas", title: "Ambil Uang Kas", hidden: false },
          ],
        },
        {
          path: "#",
          icon: "fas fa-desktop",
          title: "Validasi",
          hidden: false,
          children: [
            { path: "/validasi-jual-beli", title: "Validasi", hidden: false },
            {
              path: "/validasi-hutang",
              title: "Validasi Hutang",
              hidden: false,
            },
            // { path: '/validasi-titipan', title: 'Validasi Titipan' },
          ],
        },

        {
          path: "#",
          icon: "fas fa-folder-open",
          title: "Laporan",
          hidden: false,
          children: [
            {
              path: "#",
              title: "Laporan Barang",
              hidden: false,
              children: [
                {
                  path: "/laporan-barang-detail",
                  title: "Laporan Barang Detail",
                  hidden: false,
                },
                {
                  path: "/laporan-tambah-barang",
                  title: "Laporan Tambah Barang",
                  hidden: false,
                },
                {
                  path: "/laporan-timbang-baki",
                  title: "Laporan Timbang Baki",
                  hidden: false,
                },
                // { path: "/laporan-terima-barang", title: "Laporan Terima Barang" },
                {
                  path: "/laporan-hancur-barang",
                  title: "Laporan Hancur Barang",
                  hidden: false,
                },
                {
                  path: "/laporan-pindah-barang",
                  title: "Laporan Pindah Barang",
                  hidden: false,
                },
                {
                  path: "/laporan-barang-sumary",
                  title: "Laporan Barang Summary",
                  hidden: false,
                },
                // {
                //   path: '#', title: 'Laporan Terima Barang',
                //   children: [
                //     { path: '/laporan-terima-barang', title: 'Laporan Terima Barang', },
                //     { path: '/laporan-barang-detail', title: 'Laporan Barang Detail', }
                //   ]
                // },
              ],
            },
            {
              path: "/laporan-pesanan",
              title: "Laporan Pesanan",
              hidden: false,
            },
            {
              path: "#",
              title: "Laporan Penjualan",
              hidden: false,
              children: [
                {
                  path: "/laporan-penjualan-margin",
                  title: "Laporan Penjualan Margin",
                  hidden: false,
                },
                {
                  path: "/laporan-titip-uang",
                  title: "Laporan Titip Uang",
                  hidden: false,
                },
                {
                  path: "/laporan-stock-jual",
                  title: "Laporan Stock Jual",
                  hidden: false,
                },
                {
                  path: "/laporan-transaksi-penjualan",
                  title: "Laporan Transaksi Penjualan",
                  hidden: false,
                },
                {
                  path: "/laporan-penjualan-marketplace",
                  title: "Laporan Penjualan Marketplace",
                  hidden: false,
                },
                {
                  path: "/laporan-barang-tukar",
                  title: "Laporan Barang Tukar",
                  hidden: false,
                },
                {
                  path: "/laporan-batal-penjualan",
                  title: "Laporan Batal Penjualan",
                  hidden: false,
                },
                {
                  path: "/laporan-penjualan-sales",
                  title: "Laporan Penjualan Sales",
                  hidden: false,
                },

              ],
            },
            {
              path: "#",
              title: "Laporan Pembelian",
              children: [
                {
                  path: "/laporan-transaksi-pembelian",
                  title: "Laporan Transaksi Pembelian",
                  hidden: false,
                },
                {
                  path: "/laporan-transaksi-pembelian-history",
                  title: "Laporan History Transaksi Pembelian",
                  hidden: false,
                },
                {
                  path: "/laporan-batal-pembelian",
                  title: "Laporan Batal Pembelian",
                  hidden: false,
                },
                {
                  path: "/laporan-pembelian-sales",
                  title: "Laporan Pembelian Sales",
                  hidden: false,
                },
              ],
            },
            {
              path: "#",
              title: "Laporan Service",
              hidden: false,
              children: [
                {
                  path: "/laporan-services",
                  title: "Laporan Transaksi Service",
                  hidden: false,
                },
                {
                  path: "/laporan-batal-service",
                  title: "Laporan Batal Service",
                  hidden: false,
                },
                {
                  path: "/laporan-service-masuk",
                  title: "Laporan Service (Masuk)",
                  hidden: false,
                },
                {
                  path: "/laporan-service-selesai",
                  title: "Laporan Service (Selesai)",
                  hidden: false,
                },
                {
                  path: "/laporan-service-ambil",
                  title: "Laporan Service (Ambil)",
                  hidden: false,
                },
              ],
            },

            {
              path: "#",
              title: "Laporan Hutang",
              hidden: false,
              children: [
                {
                  path: "/laporan-transaksi-hutang",
                  title: "Laporan Transaksi Hutang",
                  hidden: false,
                },
                {
                  path: "/laporan-stock-hutang",
                  title: "Laporan Stock Hutang",
                  hidden: false,
                },
                {
                  path: "/laporan-hutang-lunas",
                  title: "Laporan Hutang Lunas",
                  hidden: false,
                },
                {
                  path: "/laporan-batal-hutang",
                  title: "Laporan Batal Hutang",
                  hidden: false,
                },
              ],
            },
            {
              path: "#",
              title: "Laporan Keuangan",
              hidden: false,
              children: [
                {
                  path: "/laporan-transaksi-keuangan-cash",
                  title: "Laporan Transaksi Keuangan Cash",
                  hidden: false,
                },
                {
                  path: "/laporan-keuangan-non-cash",
                  title: "Laporan Keuangan Non Cash",
                  hidden: false,
                },
              ],
            },
            // {
            //   path: "#",
            //   title: "Laporan Member",
            //   children: [
            //     { path: "/laporan-daftar-member", title: "Laporan Daftar Member" },
            //   ],
            // },

            {
              path: "/laporan-otorisasi",
              title: "Laporan Otorisasi",
              hidden: false,
            },
            {
              path: "/laporan-eod",
              title: "Laporan EOD",
              hidden: false,
            },
            // {
            //   path: "/laporan-authorization",
            //   title: "Laporan Otorisasi",
            // },
          ],
        },
        {
          path: "#",
          icon: "fas fa-users",
          title: "Manage Users",
          hidden: false,

          children: [
            { path: "/data-users", title: "Data Users", hidden: false },
            {
              path: "/hak-akses-users",
              title: "Hak Akses Users",
              hidden: false,
            },
          ],
        },
        {
          path: "#",
          icon: "fas fa-cog",
          title: "Utility",
          hidden: false,
          children: [
            // { path: "/settingtoko", title: "Setting Toko" },
            { path: "/settingsystem", title: "Setting System", hidden: false },
            { path: "/cetak-barcode", title: "Cetak Barcode", hidden: false },
          ],
        },
        {
          path: "/stock-opname",
          icon: "fas fa-business-time",
          title: "Stock Opname",
          hidden: false,
          children: []
        },
      ],
    };
    // this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataUser());
  }
  expandAndCollapse = (expanded) => {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }),
    });
  };

  updateTreeData(treeData) {
    this.setState({ treeData });
  }
  removeNode = (path) => {
    this.setState((state) => ({
      treeData: removeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }),
    }));
  };
  selectThis = (node, path) => {
    this.setState({ currentNode: node, path: path });
  };
  simpan() {
    console.log(this.state.treeData);
  }
  render() {
    const { searchString, searchFocusIndex, treeData } = this.state;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Page</Link>
          </li>
          <li className="breadcrumb-item active">Blank Page</li>
        </ol>
        <h1 className="page-header">Blank Page </h1>
        <Panel>
          <PanelHeader>Blank Page1</PanelHeader>

          <PanelBody>
            <div className="scrollPerfection" >
              {/* <PerfectScrollbar  options={{ suppressScrollX: true }}> */}

              <div className="row">
                <div className="col-2  mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.expandAndCollapse(true);
                    }}
                  >
                    Expand all
                  </button>{" "}
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.expandAndCollapse(false);
                    }}
                  >
                    Collapse all
                  </button>
                </div>
                <div className="col-2 text-left mt-4">
                  <input
                    className="form-control"
                    placeholder="Search"
                    value={searchString}
                    onChange={(event) =>
                      this.setState({ searchString: event.target.value })
                    }
                  />
                </div>
                <div className="col-2">
                  <Field
                    name="user_id"
                    label="Pilih User Id"
                    placeholder="Masukan User Id"
                    options={this.props.DataUsers.map((list) => {
                      let data = {
                        value: list.user_id,
                        name: list.usere_name,
                      };
                      return data;
                    })}
                    component={ReanderSelect}
                  />
                </div>
                <div className="col-2 text-right  mt-4">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      this.simpan();
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
              <SortableTree
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                treeData={treeData}
                onChange={(treeData) => this.setState({ treeData })}
                generateNodeProps={({ node, path }) => ({
                  title: (
                    <form
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.selectThis(node, path);
                      }}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          readOnly="readOnly"
                          className="form-control"
                          value={node.title}
                          onChange={(event) => {
                            const title = event.target.value;
                            this.setState((state) => ({
                              treeData: changeNodeAtPath({
                                treeData: state.treeData,
                                path,
                                getNodeKey,
                                newNode: { ...node, title },
                              }),
                            }));
                          }}
                          placeholder="Recipient's username"
                        />
                        <div className="input-group-append">
                          {node.hidden ? (
                            <>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const hidden = false;
                                  this.setState((state) => ({
                                    treeData: changeNodeAtPath({
                                      treeData: state.treeData,
                                      path,
                                      getNodeKey,
                                      newNode: { ...node, hidden },
                                    }),
                                  }));
                                }}
                                className="btn btn-primary"
                              >
                                {" "}
                                <i className="fa fa-eye"></i>{" "}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const hidden = true;
                                  this.setState((state) => ({
                                    treeData: changeNodeAtPath({
                                      treeData: state.treeData,
                                      path,
                                      getNodeKey,
                                      newNode: { ...node, hidden },
                                    }),
                                  }));
                                }}
                                className="btn btn-primary"
                              >
                                {" "}
                                <i className="fa fa-eye-slash"></i>{" "}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  ),
                })}
              />
              {/* </PerfectScrollbar> */}
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

BlankPages = reduxForm({
  form: "BlankPages",
  enableReinitialize: true,
})(BlankPages);
export default connect(maptostate)(BlankPages);
